import React,{createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import getConnection from 'services/connection'
import PropTypes from 'prop-types'

const defaultState = {
    userAccount: undefined,
    balance: '0.000',
    network: 'disconnected',
    chainID: 'NaN',
    provider: null,
    maticBalance: '0.00',
    viewEmailInput : false,
    user : null,
    sign_exchange : false,
    formatWMaticBalance: '0.00'
}
export const Context = createContext(defaultState)

const WalletProvider = ({children})=>{
    const [data, setData]=useState(defaultState)
    const [isLoading, setIsLoading] = useState(false);
    const [msg,setMsg] = useState('');

    const init = useCallback(async (provider) =>{
        return await getConnection(provider,setMsg,setData,setIsLoading);
    }, [setMsg,setData,setIsLoading]);

    const connection = async(provider) =>{
        try {
            setIsLoading(true);
            const res = await init(provider);
            setData(res)
        } catch (e) {
            console.log('debug error::',e);
        } finally {
            setIsLoading(false);
        }
    }

    let metamask = localStorage.getItem('isMetamask')
    let walletConnect = localStorage.getItem('walletConnect');

    const isFirst = useRef(true);

    useEffect(()=>{
        if(!isFirst.current){
            return;
        }
        isFirst.current = false;
        metamask = localStorage.getItem('isMetamask')
        walletConnect = localStorage.getItem('walletConnect')
        if(metamask){
            connection('metamask')
        }
        if(walletConnect){
            connection('walletConnect')
        }
    },[])

    const values = useMemo(() => ({
        data,
        connection,
        isLoading,
        setIsLoading,
        setData,
        msg,
    }), [data, connection, isLoading, setIsLoading, setData, msg]);

    return(
        <Context.Provider value={values}>
            {children}
        </Context.Provider>
    )
}

WalletProvider.propTypes = {
    children: PropTypes.node
}

export default WalletProvider