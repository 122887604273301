import React, { useContext, useEffect, useState } from 'react';
import { Box, CardMedia, Container, Modal,Card } from '@mui/material';
import { FaInstagram, FaShareAlt } from 'react-icons/fa';
import { BsTwitter } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import PublicMint from './components/PublicMint'; 
import { useFetch } from '../../hooks/useFetch'
import LoaderCircle from 'components/LoaderCircle';
import ErrorMessage from 'components/ErrorMessage';
import WhiteListFormFrame from 'views/Home/components/WhiteListFormFrame';
import ModalShare from 'components/ModalShare';
import { Context } from 'hooks/WalletContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { totalLeft } from 'services/ERC721/totalLeft';
import Web3 from 'web3';
import { useLocation } from 'react-router-dom';
import {collectionWithNFTPay} from 'api/sanity';
import { BiLinkExternal } from 'react-icons/bi';
import {
    ButtonViewCollection,
    CollectionBuyButtonLink,
    CollectionBuyContent,
    CollectionSoldOut,
    ContainerCardPublicMint,
    ContainerCardTotal,
    ContainerCollectionBuy,
    ContainerUserName,
    ContainerUserNameResponsive,
    ContentCardUserName,
    ContainerCardInfo,
    ContentCardInfo,
    DescriptionText
} from './styled';

const CollectionBuy = () => {
    const { data: wallet } = useContext(Context);
    const { t } = useTranslation("translate");
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [modalBuyCArd, setModalBuyCard] = useState(false);
    const [modalShare, setModalShare] = useState(false);
    const [reserve, setReserve] = useState(0);
    const [showReserve, setShowReserve] = useState(false);
    const address = query.get("address")
    const url = `${process.env.REACT_APP_URL_API}/collection?collection_key=${address}&domain=${process.env.REACT_APP_DOMAIN}`
    //const url = `${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&key_name=NEWS&key_val=NEWS&limit=300&page=0&order=created`;
    const { loading, error, data : collection } = useFetch(url);
    const [interval, setInterval] = useState(null);
    const [collectionsSanity,setCollectionsSanity] = useState([]);
    const [sanityError,setSanityError] = useState(null);
    const [activeNFTPay, setActiveNFTPay] = useState(null);
    const [btnMintHidden,setBtnMintHidden] = useState(true);
    const coloText = '#FEFFFF';
    const colorBtn = '#8742D9';

    useEffect(() => {
        setActiveNFTPay(null)
        if(collectionsSanity && collectionsSanity.length > 0) {
            let arrSanity = [];
            arrSanity = collectionsSanity;
            arrSanity?.map((itemSanity)=>{
                if(String(itemSanity.address).toUpperCase() === String(address).toUpperCase()){
                    setActiveNFTPay({
                        activePay:itemSanity.activePay,
                        urlPayments:itemSanity.url,
                    })
                    return 0;
                }
            })
        }
    },[collectionsSanity]);

    useEffect(()=>{
        try{
            collectionWithNFTPay()
            .then((response) => {
                if (response && response[0] && response[0].length > 0) {
                    setCollectionsSanity(response[0].collectionsList);
                }else{
                    setCollectionsSanity([])
                }
            })
            .catch((error) => {
                console.error(error);
                setSanityError(error);
                setCollectionsSanity([])
            })
        }catch(er){
            console.log(er)
        }
    },[]);

    const initNFTPay = () =>{
        setBtnMintHidden(false); // change to true for hideElement publicMint
        setActiveNFTPay(null)
    }

    useEffect(() => {
        initNFTPay();
    },[collection]);

    const initTotalLeft = async () => {
        try {
            if (interval == null && collection && collection[0] && wallet.provider) {
                let left =  await totalLeft(wallet.provider, collection[0].collection_key)
                console.log('left 1',left)
                setReserve(left)
                setShowReserve(true)
            } else if (interval == null && collection && collection[0] && !wallet.provider) {
                let left =  await totalLeft(null, collection[0].collection_key)
                console.log('left 2',left)
                setReserve(left)
                setShowReserve(true)
            }
            if (interval == null && collection && collection[0]) {
                let newInterval = setInterval(async() => {
                    let provider = null
                    if (wallet.provider)  {
                        provider = wallet.provider
                    }
                    if(collection && collection[0])  {
                        let left =  await totalLeft(provider, collection[0].collection_key)
                        setReserve(left)
                        setShowReserve(true)
                        if (left == 0) {
                            clearInterval(newInterval);
                            //window.location.reload()
                        }
                    }
                    return true
                },10000)
                setInterval(true)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        initTotalLeft();
    },[collection])

    useEffect(() => {
        let customScroll = document.querySelector(".custom-scroll")
        if(customScroll){
            customScroll.scrollTo(0,0)
        }
        window.scrollTo(0,0)
    },[]);

    const handleClickSocial = (social) => {
        window.open(social, '_blank');
    };
    const handleCloseModalFrame = () => {
        setModalBuyCard(false);
    };
    if (loading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
                <LoaderCircle text={t("collection_buy_view.loading")} />
            </Box>
        );
    }
    if (error) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
                <ErrorMessage error={error} />
            </Box>
        );
    }
    return (
        <ContainerCollectionBuy>
            {
                collection && collection.length == 0 &&
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 120px)' }}>
                    <ErrorMessage error={"Any result"} />
                </Box>
            }
            {console.log('debug collection::', collection)}
            {collection && collection[0] && (
                <>
                    <Container maxWidth="xl" sx={{ color: '#fff',zIndex:2}}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            flexDirection="column"
                            sx={{
                                mt: 2,
                                backgroundImage: `url(${collection[0].banner_url})`,
                                backgroundRepeat:'no-repeat',
                                backgroundSize: 'calc(100% - 0px) calc(100% - 0px)',
                                minWidth:'100%',
                                width: '100%',
                                height: { xs: '160px', sm: '290px', md: '340px', lg: '480px', xl: '560px' },
                                "@media screen and (max-width:780px)":{
                                    height: '220px', 
                                },
                                "@media screen and (max-width:600px)":{
                                    height: '160px', 
                                },
                                "@media screen and (max-width:350px)":{
                                    height: '120px', 
                                }
                            }}
                        >
                            <Box sx={{ flex: 1 }} />
                            {collection[0].reveal && reserve == 0 &&
                                <CollectionSoldOut
                                    display="none"
                                    justifyContent="center"
                                    alignItems="flex-end"
                                    width="100%"
                                >
                                    {t("collection_buy_view.sold_out").toUpperCase()}
                                </CollectionSoldOut>
                            }
                            {collection[0]?.user && (
                                <CollectionBuyContent>
                                    <Box sx={{flex:1}}/>
                                    <Box
                                        component={Link}
                                        to={`/profile?address=${collection[0].user.main_key}`}
                                        display="none"
                                        alignItems="center"
                                        gap="15px"
                                        sx={{ textDecoration: 'none', color: 'inherit'}}
                                    >
                                        <CardMedia
                                            component="img"
                                            src={collection[0].user.profile_pic_url}
                                            alt="img"
                                            sx={{ width: '50px', height: '50px', borderRadius: '5px' }}
                                        />
                                        <ContainerUserName>
                                            {
                                                collection && collection[0] && collection[0].user && collection[0].user.username != '' && 
                                                <React.Fragment>
                                                    {
                                                        Web3.utils.isAddress(collection[0].user.username) ?
                                                            (collection[0].user.username).substring(0,5)+ '...' +(collection[0].user.username).substring(38,54)
                                                        :
                                                        collection[0].user.username
                                                    }
                                                </React.Fragment>
                                            }
                                        </ContainerUserName>
                                        <ContainerUserNameResponsive>
                                            {
                                                collection && collection[0] && collection[0].user &&
                                                <React.Fragment>
                                                    {
                                                        Web3.utils.isAddress(collection[0].user.username) ?
                                                            (collection[0].user.username).substring(0,5)+ '...' +(collection[0].user.username).substring(38,54)
                                                        :
                                                        collection[0].user.username
                                                    }
                                                </React.Fragment>
                                            }
                                        </ContainerUserNameResponsive>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap="8px" sx={{padding:'20px 30px'}}>
                                        <Box
                                            component={FaInstagram}
                                            onClick={() => handleClickSocial('https://www.instagram.com')}
                                            size={25}
                                            color="#8349DB"
                                            sx={{ cursor: 'pointer', '&:hover':{color:'#BA9BEB'} }}
                                        />
                                        <Box
                                            component={BsTwitter}
                                            onClick={() => handleClickSocial('https://twitter.com')}
                                            size={28}
                                            color="#8349DB"
                                            sx={{ cursor: 'pointer' ,'&:hover':{color:'#BA9BEB'}}}
                                        />
                                        <Box
                                            component={FaShareAlt}
                                            onClick={() => setModalShare(true)}
                                            size={24}
                                            color="#8349DB"
                                            sx={{ cursor: 'pointer' ,'&:hover':{color:'#BA9BEB'}}}
                                        />
                                    </Box>
                                </CollectionBuyContent>
                            )}
                        </Box>
                  
                        <Box sx={{ mt: 5,width:'100%'}}>
                            <ContainerCardInfo>
                                <ContentCardInfo component={Card}>
                                    <ContentCardUserName sx={{ color:'#8742D9' }}>
                                        {collection[0].name}
                                    </ContentCardUserName>
                                    <ContainerCardTotal
                                        display="flex"
                                        alignItems="center"
                                        gap="8px"
                                        sx={{ color:coloText }}
                                    >
                                        {showReserve &&
                                        <React.Fragment>
                                            <Box>
                                                {t("collection_buy_view.minted")}: {  collection[0].collection_of - reserve }
                                            </Box>
                                            <Box>
                                                Total: {collection[0].collection_of}
                                            </Box>
                                            <Box>
                                                {t("collection_buy_view.reserved")}: { reserve }
                                            </Box>
                                        </React.Fragment>
                                        }
                                    </ContainerCardTotal>
                                    <DescriptionText dangerouslySetInnerHTML={{ __html: collection[0].description }}>
                                    </DescriptionText>
                                    {collection[0].collection_of > 0 && !btnMintHidden && 
                                        <ContainerCardPublicMint component={Card}>
                                            <PublicMint
                                                collection={collection[0]}
                                                openModalBuy={setModalBuyCard}
                                                wallet={wallet}
                                                reserve={reserve}
                                                activeNFTPay={activeNFTPay}
                                            />
                                        </ContainerCardPublicMint>
                                    }
                                    { (sanityError != '' || sanityError != null) &&
                                        <Box
                                            sx={{
                                                boxSizing: 'border-box',
                                                padding: '0px 15px',
                                                borderRadius: '5px',
                                                color: '#B5B8C0',
                                                mt: 2
                                            }}
                                        >
                                            <Box sx={{ fontSize: '14px',color:'red' }}>
                                                {sanityError}
                                            </Box>
                                        </Box>
                                    }
                                    <ButtonViewCollection
                                        LinkComponent={Link}
                                        to={`/collection?address=${collection[0].collection_key}`}
                                        endIcon={<BiLinkExternal size={20} />}
                                        sx={{
                                            border: '2px solid '+colorBtn,
                                            background:colorBtn,
                                            "&:hover":{
                                                background:'#BA9BEB'
                                            }
                                        }}
                                    >
                                        {t("collection_buy_view.view_collection")}
                                    </ButtonViewCollection>
                                    <Box sx={{width:'100%',height:'20px'}} />
                                </ContentCardInfo>
                                <Box>
                                    <Box
                                        sx={{
                                            display: { xs: 'initial', sm: 'none', md: 'none', lg: 'none', xl: 'none'},
                                            color:'#2dcccd',
                                            fontWeight: '400',
                                            fontSize: '30px',
                                            lineHeight: '29px',
                                            fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
                                        }}
                                    >
                                        {collection[0].name}
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="8px"
                                        sx={{
                                            display:{xs:'flex',sm:'none'},
                                            fontSize: '17px',
                                            mb: 1,
                                            color:'#FEFFFF',
                                            fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                            minHeight:'25px'
                                        }}
                                    >
                                        {
                                        showReserve &&
                                        <React.Fragment>
                                            <Box>
                                                {t("collection_buy_view.minted")}: {  collection[0].collection_of - reserve }
                                            </Box>
                                            <Box>
                                                Total: {collection[0].collection_of}
                                            </Box>
                                            <Box>
                                                {t("collection_buy_view.reserved")}: { reserve }
                                            </Box>
                                        </React.Fragment>
                                        }
                                    </Box>
                                    <CardMedia
                                        component={collection[0].is_video ? "video" : "img"}
                                        src={collection[0].thumb_url}
                                        autoPlay
                                        muted
                                        loop
                                        alt="nft"
                                        sx={{
                                            borderRadius: '8px',
                                            objectFit: 'contain',
                                            width: '100%',
                                            margin: '0 auto',
                                            aspectRatio: 'inherit'
                                        }}
                                    />
                                </Box>
                            </ContainerCardInfo>
                            <Box
                                sx={{
                                    display:'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '4px'
                                }}
                            >
                                <CollectionBuyButtonLink
                                    LinkComponent={Link}
                                    to={`/collection?address=${collection[0].collection_key}`}
                                    endIcon={<BiLinkExternal size={20} />}
                                    sx={{
                                        border: '2px solid '+colorBtn,
                                        background:colorBtn,
                                        "&:hover":{
                                            background:'#BA9BEB'
                                        }
                                    }}
                                >
                                    {t("collection_buy_view.view_collection")}
                                </CollectionBuyButtonLink>
                            </Box>
                        </Box>

                        
                    </Container>
                    <Modal open={modalBuyCArd} onClose={handleCloseModalFrame}>
                        <>
                            <WhiteListFormFrame close={handleCloseModalFrame} collection={collection[0]} activeNFTPay={activeNFTPay}/>
                        </>
                    </Modal>
                </>
            )}
            {collection&& collection[0]  && <ModalShare open={modalShare} setOpen={setModalShare} url={`${window.location}`} />}
        </ContainerCollectionBuy>
    );
};

CollectionBuy.propTypes = {
    location: PropTypes.object
}

export default CollectionBuy;
