const KEY_X_API = 'userApiKey'

export const setXApi = (value='') => {
  if(!value){
    return;
  }
  window.localStorage.setItem(KEY_X_API, value)
}

export const getXApi = () => {
  return window.localStorage.getItem(KEY_X_API)
}

export const determineXApi = () => {
  return getXApi() ?? process.env.REACT_APP_X_API_KEY;
}