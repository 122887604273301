import React, { useState } from 'react'
import Box from  '@mui/material/Box';
import { useNavigate,useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import FilterPriceRange from './components/FilterPriceRange';
import FilterAvailability from './components/FilterAvailability';
import FilterCategory from './components/FilterCategory';
import FilterType from './components/FilterType';


const SortNFTs = ({urlNFT, setUrlNFT,listNFT,changeFilter,setNewContent,setSliceNFT,requestIsLoad,countNfts,pageNftRef}) => {

    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const slug = query.get('SLUG')
    const key_name = query.get('key_name')
    const key_val = query.get('key_val')
    const viewAll = query.get('view_all')
    const history = useNavigate();
    let limit = listNFT;
   
    const [isImage, setIsImage] =  useState(String(query.get('format')).toUpperCase() == "IMAGE" ?true:false)
    const [isVideo, setIsVideo] = useState(String(query.get('format')).toUpperCase() == "VIDEO" ? true:false)
    const [isMp3, setIsMp3] = useState(String(query.get('format')).toUpperCase() == "TRACK" ?true:false)
    const [isPdf, setIsPdf] = useState(String(query.get('format')).toUpperCase() == "DOCUMENT" ?true:false)
    const [allTypes, setAllTypes] = useState(query.get('key_name') == "SEARCH" ?true:false)
    const [isAuction, setIsAuction] = useState(String(query.get('LIVE_AUCTION')).toUpperCase() == "TRUE" ?true:false)
    const [isBuyNow, setIsBuyNow] = useState(String(query.get('ON_SALE')).toUpperCase() == "TRUE" ?true:false)
    const [rangeBottom,setRangeBottom] = useState(query.get('PRICE_RANGE_BOTTOM') ? query.get('PRICE_RANGE_BOTTOM'):0)
    const [rangeTop,setRangeTop] =  useState(query.get('PRICE_RANGE_TOP') ? query.get('PRICE_RANGE_TOP'):0)
    const [tempRangeBottom, setTempRangeBottom] = useState(0)
    const [tempRangeTop, setTempRangeTop] = useState(0)
    const [categoriesString, setCategoriesString] = React.useState('');
    let compURlNFT = `${process.env.REACT_APP_URL_API}/nft?limit=${limit}&page=0&order=created&key_name=SEARCH&key_val=news&domain=${process.env.REACT_APP_DOMAIN}`


    const validRoute = () =>{
        if(String(location.search).length > 0){
            if(slug != null || key_name == null && key_val == null && viewAll == null){
                countNfts.current = listNFT;
                pageNftRef.current = 0;
                setSliceNFT(10)
                setNewContent([])
                setRangeBottom(0)
                setRangeTop(0)
                setIsImage(false)
                setIsVideo(false)
                setAllTypes(false)
                setIsMp3(false)
                setIsPdf(false)
                setIsAuction(false)
                setIsBuyNow(false)
                if(slug != null && slug != ''){
                    setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limit}&page=0&order=created&SLUG=${slug}`)
                }
            }
        }else{
            countNfts.current = listNFT;
            pageNftRef.current = 0;
            setSliceNFT(10)
            setNewContent([])
            setRangeBottom(0)
            setRangeTop(0)
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
            setIsAuction(false)
            setIsBuyNow(false)
            if(urlNFT == compURlNFT){
                setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limit}&page=0&order=created&key_name=SEARCH&key_val=news`)
            }else{
                setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?limit=${limit}&page=0&order=created&key_name=SEARCH&key_val=news&domain=${process.env.REACT_APP_DOMAIN}`)
            }
        }
    }
    React.useEffect(() => {
        validRoute();
    },[slug,key_name,key_val,viewAll])

    React.useEffect(() => {
        setIsImage (String(query.get('format')).toUpperCase() == "IMAGE" ?true:false)
        setIsVideo(String(query.get('format')).toUpperCase() == "VIDEO" ?true:false)
        setIsMp3(String(query.get('format')).toUpperCase() == "TRACK" ?true:false)
        setIsPdf(String(query.get('format')).toUpperCase() == "DOCUMENT" ?true:false)
        setAllTypes(query.get('key_name') == "SEARCH" ?true:false)
        setIsAuction(String(query.get('LIVE_AUCTION')).toUpperCase() == "TRUE" ?true:false)
        setIsBuyNow(String(query.get('ON_SALE')).toUpperCase() == "TRUE" ?true:false)
        setTempRangeBottom(query.get('PRICE_RANGE_BOTTOM') ? query.get('PRICE_RANGE_BOTTOM'):0)
        setTempRangeTop(query.get('PRICE_RANGE_TOP') ? query.get('PRICE_RANGE_TOP'):0)
    },[query])

    const removeFilter = () =>{
        countNfts.current = listNFT;
        pageNftRef.current = 0;
        setSliceNFT(10)
        setNewContent([])
        resetFilter("type");
        history('/explore')
        setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limit}&page=0&order=created&key_name=SEARCH&key_val=news`)
    }

    const resetFilter = (filter) =>{
        if(filter === "priceRange"){
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
            setIsAuction(false)
            setIsBuyNow(false)
            setCategoriesString('')
            return;
        }
        if(filter == "availability"){
           
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
            setCategoriesString('')
            return ;
        }

        if(filter === "type"){
           
            setIsAuction(false)
            setIsBuyNow(false)
            setCategoriesString('')
            return;
        }

        if(filter === "currency"){
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
            setIsAuction(false)
            setIsBuyNow(false)
            setCategoriesString('')
            return;
        }

        if(filter === "category"){
   
            setIsAuction(false)
            setIsBuyNow(false)
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
        }
    }

    const handleClickType= (check,value) => {
        countNfts.current = listNFT;
        pageNftRef.current = 0;
        setSliceNFT(10)
        setNewContent([])
        resetFilter("type");
        if(value == 'VIDEO'){
            changeFilter.current = true;
            setIsImage(false)
            setIsVideo(check)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
        }
        if(value == 'IMAGE'){
            changeFilter.current = true;
            setIsImage(check)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
        }
        if(value == 'DOCUMENT'){
            changeFilter.current = true;
            setIsPdf(check)
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
        }
        if(value == 'TRACK'){
            changeFilter.current = true;
            setIsMp3(check)
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsPdf(false)
        }
        if(value == 'SEARCH'){
            changeFilter.current = true;
            setIsImage(false)
            setIsVideo(false)
            setIsMp3(false)
            setIsPdf(false)
            setAllTypes(check)
            if(check){
                history(`/explore?limit=${limit}&page=0&order=created&key_name=SEARCH&key_val=news`)
                setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?limit=${limit}&page=0&order=created&key_name=SEARCH&key_val=news&domain=${process.env.REACT_APP_DOMAIN}`)
                return;
            }else{
                removeFilter()
                return;
            }
        }

        if(check){
            history(`/explore?limit=${limit}&page=0&order=created&format=${value}`)
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limit}&page=0&order=created&format=${value}`)
            //setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limit}&page=0&order=created&key_name=${value}&key_val=1`)
        }else{
            removeFilter()
        }
    }


    const handleChangeCategories = (value) => {
        resetFilter("category");
        countNfts.current = listNFT;
        pageNftRef.current = 0;
        setSliceNFT(10)
        setNewContent([])
        changeFilter.current = true;
        history(`/explore?limit=${limit}&page=0&order=created&key_name=CATEGORY&key_val=${value}`)
        let url = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limit}&page=0&order=created&key_name=CATEGORY&key_val=${value}`;
        if(url == urlNFT){
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?limit=${limit}&page=0&order=created&key_name=CATEGORY&key_val=${value}&domain=${process.env.REACT_APP_DOMAIN}`)
        }else{
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limit}&page=0&order=created&key_name=CATEGORY&key_val=${value}`)
        }
    }
    return (
        <Box 
            sx={{
                position:'sticky',
                width:{xs:'100%',sm:'320px'},
                top: 0,
                p:'0px 5px',
                '@media screen and (max-width: 768px)':{
                    position:'block',
                    width:'100%'
                }
            }}
        >
            <FilterPriceRange 
                requestIsLoad={requestIsLoad}
                rangeBottom={rangeBottom}
                setRangeBottom={setRangeBottom}
                rangeTop={rangeTop}
                setRangeTop={setRangeTop}
                tempRangeBottom={tempRangeBottom}
                setTempRangeBottom={setTempRangeBottom}
                tempRangeTop={tempRangeTop}
                setTempRangeTop={setTempRangeTop}
                countNfts={countNfts}
                pageNftRef={pageNftRef}
                setSliceNFT={setSliceNFT}
                setNewContent={setNewContent}
                resetFilter={resetFilter}
                changeFilter={changeFilter}
                setUrlNFT={setUrlNFT}
                limit={limit}
                listNFT={listNFT}
            />


            <FilterAvailability
                requestIsLoad={requestIsLoad}
                isBuyNow={isBuyNow}
                setIsBuyNow={setIsBuyNow}
                isAuction={isAuction}
                setIsAuction={setIsAuction}
                countNfts={countNfts}
                pageNftRef={pageNftRef}
                setSliceNFT={setSliceNFT}
                setNewContent={setNewContent}
                resetFilter={resetFilter}
                changeFilter={changeFilter}
                setUrlNFT={setUrlNFT}
                limit={limit}
                listNFT={listNFT}
                removeFilter={removeFilter}
            />
        
            <FilterType 
                requestIsLoad={requestIsLoad}
                handleClickType={handleClickType}
                isImage={isImage}
                isVideo={isVideo}
                isMp3={isMp3}
                isPdf={isPdf}
                allTypes={allTypes}
            />
            
            <FilterCategory 
                requestIsLoad={requestIsLoad}
                categoriesString={categoriesString}
                setCategoriesString={setCategoriesString}
                handleChangeCategories={handleChangeCategories}
            />
        </Box>
    )
}
SortNFTs.defaultProps = {
    urlNFT:'',
    setUrlNFT: ()=>{},
    listNFT:10,
    changeFilter:{},
    setNewContent: ()=>{},
    setSliceNFT: ()=>{},
    requestIsLoad:false,
    countNfts:{current:100},
    pageNftRef:{current:0}
}

SortNFTs.propTypes = {
    urlNFT: PropTypes.string,
    setUrlNFT:PropTypes.func,
    listNFT: PropTypes.number,
    changeFilter: PropTypes.object,
    setNewContent : PropTypes.func,
    setSliceNFT: PropTypes.func,
    requestIsLoad: PropTypes.bool,
    countNfts : PropTypes.object,
    pageNftRef: PropTypes.object
}
export default SortNFTs
