import {  Button, Divider, List, ListItem,Input,CircularProgress} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useTranslation } from 'react-i18next'
import avalancheIcon from 'assets/logos/avalanche_logo.svg'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { isEnglish } from 'services/determinateLanguage'
import { DrawerMobileContext } from 'hooks/DrawerMobileContext'
import { sign } from 'services/Utils/signature'
import { saveUser } from 'services/User/saveUser'
import jazzicon from "@metamask/jazzicon"
import PropTypes from 'prop-types'


const LogIn = ({data}) => {
    const { t } = useTranslation("translate");
    const [notifications,setNotifications] = useState('');
    const [viewEmailInput,setViewEmailInput] = useState(true);
    const [emailAcceptButtonValid,setEmailAcceptButtonValid] = useState(false);
    const {setOpenWallet} = React.useContext(DrawerMobileContext);
    const [initRegistrationEmail,setInitRegistrationEmail] = useState(false);
    const [msgError,setMsgError] = useState('');
    const avatarRef = useRef();

    const getNetWorkName = ()=>{
        if(data && data.chainID && data.chainID == process.env.REACT_APP_ETHEREUM_NETWORK){
            return "Ethereum" 
        }
        if(data && data.chainID && data.chainID == process.env.REACT_APP_POLYGON_NETWORK){
            return "Avalanche"
        }
        if(data && data.chainID && data.chainID != process.env.REACT_APP_POLYGON_NETWORK && data.chainID != process.env.REACT_APP_ETHEREUM_NETWORK){
            return "Unknown network"
        }
    }
    
    const [itemWallet,setItemWallet] = useState([
        {value:t('topbar.wallet.title'),icon:''},
        {value:
            <React.Fragment>
                {(data.userAccount).substring(0, 4).concat('...').concat((data.userAccount).substring(38, 42))}
                <Box sx={{width:'10px'}} />
                {t('topbar.wallet.modal_connected') + ' ' + getNetWorkName()}<Checkbox icon={<CheckCircleIcon color='success' />} checkedIcon={<CheckCircleIcon color='success' />} />
            </React.Fragment>,icon:''
        },
    ]);

    useEffect(() => {
        const element = avatarRef.current;
        if (element && data.userAccount) {
            const addr = data.userAccount.slice(2, 10);
            const seed = parseInt(addr, 16);
            const icon = jazzicon(20, seed); //generates a size 20 icon
            if (element.firstChild) {
                element.removeChild(element.firstChild);
            }
            element.appendChild(icon);
        }
    }, [data.userAccount, avatarRef]);


    const handleNotifications = (e) =>{
        e.preventDefault(); 
        setEmailAcceptButtonValid(new RegExp(/^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(e.target.value))
        setNotifications(e.target.value);
    }


    const handleReceiveNotifications = async(e)=>{
        e.preventDefault();
        setInitRegistrationEmail(true);
        setMsgError('')
        try{
            let  { signature, message } = await sign("Approve my intention to receive notifications at",data.userAccount,data.provider).catch(
                (errorSignature)=>{
                    setInitRegistrationEmail(false);
                    setMsgError(errorSignature.message)
                }
            )
            if(signature){
                await saveUser({
                    address : data.userAccount,
                    email : notifications,
                    signature : signature,
                    message : message
                }).then(()=>{
                    setViewEmailInput(false)
                    localStorage.setItem('viewEmailInput',JSON.stringify(viewEmailInput))
                    setOpenWallet(false)
                    setInitRegistrationEmail(false);
                    setMsgError('')
                }).catch((catchErr)=>{
                    setInitRegistrationEmail(false);
                    setMsgError(catchErr.message)
                })
               
            }
        }catch(err){
            console.log(err);
            setInitRegistrationEmail(false);      
        }  
    }

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? String(cookie).substring(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }

    const disconnect = ()=>{
        if(data.provider) {
            localStorage.removeItem('wallet')
            localStorage.removeItem('isMetamask')
            localStorage.removeItem('walletConnect')
            localStorage.removeItem('network')
            data.provider.request({
                method: "eth_requestAccounts",
                params: [
                    {
                        eth_accounts: {}
                    }
                ]
            });
            deleteAllCookies();
            window.location.reload();
        }
    }

    
    const getIcon = (index) =>{
        if(index == 1){
            return <Box ref={avatarRef}></Box>
        }
        if(index == 2 || index == 3){
            return <img src={avalancheIcon} style={{height:'25px', width:'25px'}} />
        }
    }

    const getLang = () =>{
        if(isEnglish()){
            return 'en-US'
        }else{
            return 'es-ES'
        }
    }

    React.useEffect(() => {
        if(data && data.userAccount && data.userAccount != 'undefined'){
            setItemWallet([
                {value:t('topbar.wallet.title')},
                {value:
                    <React.Fragment>
                        {(data.userAccount).substring(0, 4).concat('...').concat((data.userAccount).substring(38, 42))}
                        <Box sx={{width:'10px'}} />
                        {t('topbar.wallet.modal_connected') + ' ' + getNetWorkName()}<Checkbox icon={<CheckCircleIcon color='success' />} checkedIcon={<CheckCircleIcon color='success' />} />
                    </React.Fragment>
                },
                {value:<React.Fragment>{data.formatMaticBalance.substring(0,6)} AVAX</React.Fragment>},
                {value:<React.Fragment>{(data.formatWMaticBalance+'').toString().substring(0,6)} WAVAX</React.Fragment>}
            ]);
        }     
    },[data])
 

    return (
        <>
            <List
                component="nav"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                }}
            >
                {
                    itemWallet?.map((item,index)=>{
                        return <ListItem key={index} button>
                            {
                                index == 0 ?
                                <Box >
                                    <Typography  component="div" fontSize={"15px"} sx={{fontFamily:'Futura,Trebuchet MS,Arial,sans-serif'}} >
                                        {item.value}
                                    </Typography>
                                </Box>
                                :
                                <Box sx={{display:'flex',width:'100%',justifyContent:'flex-start',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif'}}>
                                    <Box
                                        display='flex' 
                                        alignItems='center' 
                                        justifyContent='center'
                                        sx={{gap:'0.5rem', padding:'0.1rem', fontWeight: 600}}
                                    >
                                        {getIcon(index)} {item.value}
                                    </Box>
                            </Box>
                            }
                        </ListItem>
                    })
                }
           
            </List>
            <Divider />

            { data && (data.viewEmailInput && viewEmailInput) &&
                <Box sx={{paddingTop:'1rem'}}>
                    <Box sx={{display:'flex',width:'100%',justifyContent:'center'}}>
                        <form onSubmit={(e)=>handleReceiveNotifications(e)}>
                            <Box sx={{width:'100%',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif'}}>
                                <label>{t("topbar.wallet.modal_receive_notifications_label")}</label>
                            </Box>
                            <Input
                                className="notranslate"
                                value={notifications}
                                onChange={(e)=>{handleNotifications(e)}}
                                type='email' placeholder="E-mail"
                                disableUnderline
                                disabled = {initRegistrationEmail}
                                sx={{
                                    borderRadius: '10px',
                                    boxSizing: 'border-box',
                                    border:'solid 1px rgba(224, 224, 224,.1213)',
                                    outlineStyle:'red',
                                    padding: '3px',
                                    fontSize:'14px',
                                    background:'rgba(224, 224, 224,.43)',
                                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif',
                                    '@media screen and (max-width: 920px)': {
                                        width:'100%'
                                    },
                                    "&:hover":{
                                        background:'rgb(224, 224, 224)'
                                    },
                                    "&:focus::-webkitInputPlaceholder":{
                                        color:'red'
                                    }
                                }}
                            />
                            <Button
                                className="notranslate"
                                disabled = { !emailAcceptButtonValid || initRegistrationEmail}
                                onClick={(e) => handleReceiveNotifications(e)}
                                sx={{
                                    marginLeft:'5px',
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif',
                                    '&:hover': {
                                        backgroundColor: '#000'
                                    },
                                    '@media screen and (max-width: 920px)': {
                                        width:'50%',
                                        marginTop:'5px',
                                        marginLeft:'0px'
                                }
                                }}
                                variant="contained"
                                size="small"
                            >
                                {
                                    initRegistrationEmail ? 
                                    <CircularProgress size={24}
                                        sx={{
                                            color: '#fff',
                                            marginLeft: '5px'
                                        }}
                                    />
                                    :
                                    t("topbar.wallet.modal_receive_notifications_btn")
                                }
                                
                            </Button>
                        </form>
                    </Box>
                    <center>
                        {
                            msgError != '' &&
                            <small style={{color:'red'}}>{msgError}</small>
                        }
                    </center>
                </Box>
            }
            <Box sx={{height:'356px',overflow:'auto'}}>
                <iframe id='iframe-widget' src={`https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=true&amount=10&from=usdt&horizontal=false&lang=${getLang()}&link_id=5e644f65bb9b85&locales=false&logo=true&primaryColor=A858D8&to=avax&toTheMoon=false`} style={{
                    //"height: 356px; width: 100%; border: none"
                    width: '100%',
                    height : '356px',
                    border : 'none'
                }}/>
            </Box>
            <List
                component="div"
                sx={{
                    margin:'15px auto',
                    height:'20%',
                }}
            >
                <ListItem sx={{display:'flex', justifyContent:'center'}}>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={disconnect}
                        sx={{
                            backgroundColor: '#A630D9',
                            color:'#fff',
                            '&:hover': {
                                backgroundColor: '#A659D9',
                            }
                        }}
                    >
                        {t('topbar.wallet.modal_logout_btn')}
                    </Button>
                </ListItem>
            </List>
        </>
    );
}

LogIn.propTypes = {
    data: PropTypes.object,
};


  

export default LogIn;
