import * as React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';


const MultiSelect = ({ id, name, params, rows, setRows, curators }) => {
  const [curatorsList, setCuratorsList] = React.useState(params.formattedValue);

  const handleChangeList = (event, value) => {
    setCuratorsList(value);
    const updatedRows = rows.map((artist) => {
      if (artist.id === params.id) {
        return {
          ...artist,
          curators: value && value.length > 0 ? value : [],
          formattedValue: value && value.length > 0 ? value : [],
          isEdit: true,
        };
      }
      return artist;
    });
    setRows(updatedRows);
  };

  return (
    <Autocomplete
      multiple
      id={id}
      options={curators}
      value={curatorsList}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleChangeList}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={option.id} label={option.name} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextField {...params} label={name} variant="outlined" />}
      sx={{ width: '100%' }}
    />
  );
};

MultiSelect.defaultProps = {
  id: '',
  name: '',
  params: {},
  rows: [],
  setRows: () => console.log(''),
};

MultiSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  params: PropTypes.object,
  rows: PropTypes.array,
  curators: PropTypes.array,
  setRows: PropTypes.func,
};

export default MultiSelect;
