import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

let day = 86399000; // one day
let nMonth = day * ((365)*100); // 30 days
let maxDate = (new Date().getTime()-day) + nMonth; // max date selected


const colorText = '#000';
const colorBtn = '#8742D9';

let color ={
    "50":colorBtn,
    "100":colorBtn,
    "200":colorBtn,
    "300":colorBtn,
    "400":colorBtn,
    "500":colorBtn,
    "600":colorBtn,
    "700":colorBtn,
    "800":colorBtn,
    "900":colorBtn,
    "A100":colorBtn,
    "A200":colorBtn,
    "A400":colorBtn,
    "A700":colorBtn
}



const theme = createTheme({
    palette: {
        primary: color,
        background: 'red',
        textColor:colorText
    },
    typography:{
        primary:colorText,
        color:colorText
    }
});

const CalendarCustom = ({date,setDate,name,minDate,maxDate}) => {
    
    return (
        <ThemeProvider theme={theme} >
            <Box sx={{
                "&.MuiCalendarOrClockPicker-root":{
                    background: 'red',
                },
                "&.MuiPickersCalendarHeader-root":{
                    color:colorText
                },
                "&,button":{
                    color:colorText
                },
                "&,button>.Mui-selected":{
                    color:colorText
                },
                "&,div":{
                    color:colorText
                },
                ".MuiPickersCalendarHeader-label ":{
                    color:colorText
                }
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"26/10/2022"}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="year"
                        value={date[name]}
                        onChange={(newValue) => {
                            let format = new Date(newValue);
                            format.toLocaleDateString([],{
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            })
                            setDate({...date,[name]:format})
                        }}
                        InputProps={{
                            style:{
                                color:colorText,
                                "&.MuiPickersCalendarHeader-label":{
                                    color:colorText
                                }
                            }
                        }}
                        renderInput={(params) => <TextField {...params} 
                            sx={{
                                color:colorText,
                                borderColor: colorText,
                                "& ,input":{
                                    color:colorText
                                },
                                "& label.Mui-focused":{
                                    color:colorText
                                },
                                "& label":{
                                    color:colorText,
                                    borderColor: colorText
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'green',
                                },
                                '& , fieldset': {
                                    borderColor: colorText
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: colorText
                                    },
                                    '&:hover fieldset': {
                                        borderColor: colorBtn
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: colorBtn
                                    }
                                }
                            }}
                        />
                        }
                        minDate={minDate}
                        maxDate={maxDate}
                        mask="__/__/____"
                        OpenPickerButtonProps={{
                            color:colorText
                        }}                        
                    />
                </LocalizationProvider>
            </Box>
        </ThemeProvider>
    );
};

CalendarCustom.defaultProps = {
    date:{date:new Date()},
    setDate: ()=>{},
    name:"date",
    minDate:new Date().getTime()+day,
    maxDate:maxDate
};

CalendarCustom.propTypes = {
    date: PropTypes.object,
    setDate: PropTypes.func,
    name: PropTypes.string,
    minDate: PropTypes.any,
    maxDate: PropTypes.any
};

export default CalendarCustom;