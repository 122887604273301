import React, { useState } from 'react'
import { Box, Container } from '@mui/material';
import NFTMedia from './NFTMedia';
import NFTDataMedia from './NFTDataMedia';
import ModalTransfer from './ModalTransfer/ModalTransfer';
import Buttons from '../components/Buttons';
import ModalSetupNFT from './ModalSetupNFT';
import AuctionSetup from './ModalSetupNFT/components/AuctionSetup';
import ModalSelectConfigureNFT from './ModalSelectConfigureNFT';
import PropTypes from 'prop-types';
import ModalBurnNFT from './ModalBurnNFT';

const ModalsNFTSetup = ({
    nft,
    openModalSetupNFT,
    setOpenModalSetupNFT,
    setOpenModalSelectconfiguration,
    openModalSelectconfiguration,
    setOpenModalAuction
})=>{
    return (
        <React.Fragment>
            { nft &&
                    <ModalSetupNFT
                        sale = { (nft && nft.sale) ? nft.sale : {}}
                        open={openModalSetupNFT}
                        openModalSetupNFT={openModalSetupNFT}
                        setOpenModalSetupNFT={setOpenModalSetupNFT}
                        nftImage={nft && nft.metadata.is_video ? nft.thumb_gif:nft.thumb_url_large}
                        tokenId={nft && nft.token_id && nft.token_id}
                        projectKey={nft && nft.collection_key}
                        isVideo={nft && nft.metadata && nft.metadata.json_data.isVideo ? true : false}
                        setOpenModalSelectconfiguration={setOpenModalSelectconfiguration}
                    />
                }
                 <ModalSelectConfigureNFT 
                    nft = {nft}
                    projectKey={nft && nft.collection_key}
                    image={nft && nft.metadata.is_video ? nft.thumb_gif:nft.thumb_url_large}
                    isVideo={nft && nft.metadata && nft.metadata.json_data.isVideo ? true : false}
                    open={openModalSelectconfiguration} 
                    onClose={setOpenModalSelectconfiguration}
                    auction={setOpenModalAuction}
                    sale={setOpenModalSetupNFT}
                />
        </React.Fragment>
    )
}

ModalsNFTSetup.propTypes = {
    nft: PropTypes.object,
    openModalSetupNFT: PropTypes.bool,
    setOpenModalSetupNFT: PropTypes.func,
    setOpenModalSelectconfiguration: PropTypes.func,
    openModalSelectconfiguration: PropTypes.func,
    setOpenModalAuction: PropTypes.func
}


const NFTData = ({nft, setBidTx, bids, reloadPage}) => {
    const [openModalTransfer, setOpenModalTransfer]=useState(false);
    const [openModalSelectconfiguration, setOpenModalSelectconfiguration]=useState(false);
    const [openModalSetupNFT, setOpenModalSetupNFT]=useState(false);
    const [openModalAuction, setOpenModalAuction]=useState(false);
    const [openModalBurnNFT,setOpenModalBurnNFT]=useState(false);

    return (
        <Container maxWidth="xl" sx={{marginTop:'1rem'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap:'10px',
                    '@media screen and (max-width: 1030px)': {
                        flexDirection: 'column'
                    }
                }}
            >
                <NFTMedia nft={nft} />
                <NFTDataMedia 
                    nft={nft} 
                    setBidTx={setBidTx} 
                    bids={bids} 
                    reloadPage={reloadPage}
                >
                    <Buttons 
                        nft={nft}
                        setOpenModalTransfer={setOpenModalTransfer}
                        setOpenModalSelectconfiguration={setOpenModalSelectconfiguration} 
                        setOpenModalBurnNFT={setOpenModalBurnNFT}
                    />
                </NFTDataMedia>
                <ModalTransfer
                    open={openModalTransfer}
                    onClose={()=>setOpenModalTransfer(false)}
                    nft={nft}
                />
                <ModalBurnNFT
                    nft = {nft}
                    projectKey={nft && nft.collection_key}
                    image={nft && nft.metadata.is_video ? nft.thumb_gif:nft.thumb_url_large}
                    isVideo={nft && nft.metadata && nft.metadata.json_data.isVideo ? true : false}
                    open={openModalBurnNFT} 
                    onClose={setOpenModalBurnNFT}
                />
               
                <AuctionSetup 
                    auction = { (nft && nft.auction) ? nft.auction : {}}
                    image={nft && nft.metadata.is_video ? nft.thumb_gif:nft.thumb_url_large}
                    tokenId={nft && nft.token_id}
                    projectKey={nft && nft.collection_key}
                    isVideo={nft && nft.metadata && nft.metadata.json_data.isVideo ? true : false}
                    isOpen={openModalAuction} 
                    isClosed={setOpenModalAuction}
                    selector={setOpenModalSelectconfiguration}
                    setOpenModalSelectconfiguration={setOpenModalSelectconfiguration}

                />
                <ModalsNFTSetup 
                    nft={nft}
                    openModalSetupNFT={openModalSetupNFT}
                    setOpenModalSetupNFT={setOpenModalSetupNFT}
                    setOpenModalSelectconfiguration={setOpenModalSelectconfiguration}
                    openModalSelectconfiguration={openModalSelectconfiguration}
                    setOpenModalAuction={setOpenModalAuction}
                />
            </Box>
        </Container>
    )
}

NFTData.propTypes = {
    nft: PropTypes.object.isRequired,
    setBidTx : PropTypes.func,
    bids : PropTypes.array,
    reloadPage : PropTypes.func
} 

export default NFTData