import React, { useRef, useState } from 'react'
import { Box, Button, Container, Grid } from '@mui/material'
import ImagePreview from 'components/ImagePreview';
import ButtonStyled from 'components/ButtonStyled';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { sign } from 'services/Utils/signature';

import { Context } from 'hooks/WalletContext'
import { isValidFormat, isVideo } from 'services/Utils/validateFileFormat';
import LoaderModal from 'components/LoaderModal';
import { nameAndSymbol } from 'services/ERC721/nameAndSymbol';
import { useTranslation } from 'react-i18next';
import { TextField, UploadFile } from './styles/styles';
import PropTypes from 'prop-types'
import ErrorMessage from 'components/ErrorMessage';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import customToolBar from './utils/customToolBar';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const splitValidationSchema = Yup.object().shape({
    wallet: Yup.string(),
    comission: Yup.number().positive('Invalid commission'),
});

const collectionSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is required'),
    url: Yup.string()
        .url('Invalid url').notRequired(),
    commission: Yup.number()
        .min(0, 'Too Low!')
        .max(10, 'Too High!')
        .positive('Invalid commission')
        .required('Resale fee required'),
    image: Yup.mixed()
    .nullable() 
    .test("FILE_SIZE", "Uploaded file is too big.",
        value => !value || (value && value.size <= 20900000000)),
    splitWallet: Yup.array().of(splitValidationSchema),
    banner: Yup.mixed()
    .nullable() 
    .test("FILE_SIZE", "Uploaded file is too big.",
        value => !value || (value && value.size <= 20900000000))
});

const formats = ['PNG','GIF','JPG','MP4','JPEG']

const FormBody = ({
    errors,
    values,
    setValues,
    touched,
    hiddenFileInput,
    handleDragOver,
    handleDrop,
    handleFileUpload,
    
    hiddenFileInputBanner,
    handleDropBanner,
    handleDragOverBanner,
    handleFileInputBanner,
    msgFile,
    msgFileBanner,
    
    editorState,
    setEditorState,
    editorTemp,
    setEditorTemp,
    errorEditor
    }) =>{
    const { t } = useTranslation("translate");
    return (
        <Container maxWidth='sm'>
            <Box>
                <Box 
                    component='label'
                    sx={{
                        fontSize:'25px',
                        marginBottom:'0px',
                        marginTop:'0px',
                        color:'#000',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
                    }}
                >
                    {t('create_collection.upload_image')}
                </Box>
                <Box 
                    sx={{
                        fontSize:'14px',
                        marginTop:'10px',
                        marginBottom:'10px',
                        color:'#9B9B9B',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
                    }}
                >
                    {t('create_collection.drag_or_img_or_video')}
                </Box>
                <Box
                    component='section'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onClick={() => hiddenFileInput.current.click()}
                    sx={{
                        border: '1px solid #E5E5E5',
                        height: '200px',
                        width: '100%',
                        borderRadius: '8px',
                        padding: '10px',
                        cursor: 'pointer',
                    }}
                >
                    <Box 
                        component='article' 
                        display='flex' 
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <UploadFileIcon htmlColor='#9B9B9B' fontSize='large' />
                        <Box 
                            sx={{
                                fontSize:'14px',
                                color:'#9B9B9B',
                                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
                            }}
                        >
                            PNG, GIF, JPG, MP4 Max 50MB.
                        </Box>
                    </Box>
                </Box>
                <UploadFile 
                    data-testid='image-collection'
                    type='file' 
                    ref={hiddenFileInput}
                    accept="image/png, image/gif, image/jpeg, video/mp4"
                    name='image'
                    onChange={handleFileUpload}
                    multiple={false}
                />
            </Box>
            {errors.image && touched.image ? (
                <div style={{color:'#dc3545',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{errors.image}</div>
            ) : null}
            {
                msgFile != '' && 
                <div style={{color:'#dc3545',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{msgFile}</div>
            }
            <Box>
                <Box 
                    component='label'
                    sx={{
                        fontSize:'25px',
                        marginBottom:'0px',
                        marginTop:'0px',
                        color:'#000',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
                    }}
                >
                    {t('create_collection.upload_banner')}
                </Box>
                <Box 
                    sx={{
                        fontSize:'14px',
                        marginTop:'10px',
                        marginBottom:'10px',
                        color:'#9B9B9B',
                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    }}
                >
                    {t('create_collection.drag_or_choose')}
                </Box>
                <Box
                    component='section'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    onDrop={handleDropBanner}
                    onDragOver={handleDragOverBanner}
                    onClick={() => hiddenFileInputBanner.current.click()}
                    sx={{
                        border: '1px solid #E5E5E5',
                        height: '200px',
                        width: '100%',
                        borderRadius: '8px',
                        padding: '10px',
                        cursor: 'pointer',
                    }}
                >
                    <Box 
                        component='article' 
                        display='flex' 
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <UploadFileIcon htmlColor='#9B9B9B' fontSize='large' />
                        <Box 
                            sx={{
                                fontSize:'14px',
                                color:'#9B9B9B',
                                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                            }}
                        >
                            PNG, GIF, JPG Max 2MB.
                        </Box>
                        <Box 
                            sx={{
                                fontSize:'14px',
                                color:'#9B9B9B',
                                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                            }}
                        >
                            {t("profile.modal_edit.recommended")}: (1400 x 400)
                        </Box>
                    </Box>
                </Box>
                <UploadFile 
                    data-testid='image-banner'
                    type='file' 
                    ref={hiddenFileInputBanner}
                    accept="image/png, image/gif, image/jpeg, video/mp4"
                    name='banner'
                    onChange={handleFileInputBanner}
                    multiple={false}
                />
            </Box>
            {errors.banner && touched.banner ? (
                <div style={{color:'#dc3545',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{errors.banner}</div>
            ) : null}
            {
                msgFileBanner != '' && 
                <div style={{color:'#dc3545',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{msgFileBanner}</div>
            }
            <Box sx={{marginTop:'1rem', marginBottom:'1rem'}}>
                <Box 
                    component='label' 
                    htmlFor='name'
                    sx={{
                        fontSize:'25px'
                    }}
                >
                    {t('create_collection.collection_name')}<span style={{color:'#dc3545'}}>*</span>
                </Box>
                <TextField
                    id='name'
                    type='text'
                    name='name'
                    placeholder={t('create_collection.collection_name')}
                />
                {errors.name && touched.name ? (
                    <div style={{color:'#dc3545'}}>{errors.name}</div>
                ) : null}
            </Box>
            <Box sx={{marginBottom:'1rem'}}>
                <Box 
                    component='label' 
                    htmlFor='description'
                    sx={{
                        fontSize:'25px'
                    }}
                >
                    {t('create_collection.description')} <span style={{color:'#dc3545'}}>*</span>  <Box component="span" sx={{color:'#9B9B9B',fontSize:'14px'}}>({t('create_collection.max_characters')})</Box>
                </Box>
                <Box sx={{background:'#464646',padding:'10px'}}>
                    <Box
                        id='description'
                        name='description'
                        component={Editor}
                        toolbar={customToolBar}
                        placeholder={t('create_collection.description')}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={setEditorState}
                        marginX={10}
                        onChange={
                            ()=>{
                                if(editorState && editorState.getCurrentContent){
                                    if(String(draftToHtml(convertToRaw(editorState.getCurrentContent()))).length <= 3500){
                                        setEditorTemp(editorState)
                                        setValues({...values,description:editorState})
                                    }else{
                                        setValues({...values,description:editorTemp})
                                        setEditorState(editorTemp)
                                    }
                                }
                            }
                        }
                    />
                </Box>
                {errorEditor || editorState && editorState.getCurrentContent && String(draftToHtml(convertToRaw(editorState.getCurrentContent()))) == "<p></p>\n" ? (
                    <div style={{color:'#dc3545',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>Description is required</div>
                ) : null}
            </Box>
            <Box sx={{marginBottom:'1rem'}}>
                <Box 
                    component='label'
                    htmlFor='url' 
                    sx={{
                        fontSize:'25px'
                    }}
                >
                   External url<Box component="span" sx={{color:'#9B9B9B',fontSize:'14px'}}> (users can click to learn more about your artwork, e.g. your website)</Box>
                </Box>
                <TextField
                    id='url'
                    type='pathname'
                    name='url'
                    placeholder='https://myblog.com'
                />
                {errors.url && touched.url ? (
                    <div style={{color:'#dc3545'}}>{errors.url}</div>
                ) : null}
            </Box>
            <Box sx={{marginBottom:'1rem'}}>
                <Box 
                component='label' 
                htmlFor='resale'
                sx={{
                    fontSize:'25px'
                }}
                >    
                {t('create_collection.resale_fee')} <span style={{color:'#dc3545'}}>*</span>  <Box component="span" sx={{color:'#9B9B9B',fontSize:'14px'}}>(0-10%)</Box>
                </Box>
                <TextField
                    id='resale'
                    type='number'
                    placeholder='0-10%'
                    name='commission'
                    sx={{
                        width:'100%',
                    }}
                />
                {errors.commission && touched.commission ? (
                    <div style={{color:'#dc3545'}}>{errors.commission}</div>
                ) : null}
            </Box>
            <FieldArray
                name="splitWallet"
                render={arrayHelpers => (
                    <div>
                        {(values.splitWallet && values.splitWallet.length > 0) ?
                        <>    
                            {
                            values.splitWallet.map((attribute, index) => (
                                <div key={index}>
                                    <TextField
                                        type='text'
                                        placeholder={t('create_collection.address_wallet')}
                                        name={`splitWallet.${index}.wallet`}
                                        style={{marginBottom:'0.5rem'}}
                                    />
                                    <TextField
                                        type='text'
                                        placeholder={t('create_collection.commision')}
                                        name={`splitWallet.${index}.comission`}
                                        style={{marginBottom:'0.5rem'}}
                                    />
                                    <Box
                                        sx={{
                                            width:'100%',
                                            display:'flex',
                                            justifyContent:'flex-end'
                                        }}
                                    >
                                        <Button 
                                            type='button'
                                            onClick={() => arrayHelpers.remove(index)}
                                            sx={{marginBottom:'0.5rem'}}
                                        >
                                            {t('create_collection.remove_wallet')} - #{index + 1}
                                        </Button>
                                    </Box>
                                </div>
                            ))}
                            <Button
                                type='button'
                                onClick={() => arrayHelpers.push('')}
                                sx={{
                                    display: values.splitWallet.length === 10 ? 'none' : 'block',
                                    marginTop:'0.5rem', 
                                    marginBottom:'0.5rem',
                                }}
                            >
                                 {t('mint_nft.add')}
                            </Button>
                        </>: (
                            <Button
                                type='button'
                                onClick={() => arrayHelpers.push('')}
                                sx={{marginTop:'0.5rem'}}
                            >
                                {t('mint_nft.add')}
                            </Button>
                        )}
                    </div>
                )}
            />
            <Box component="span" sx={{color:'#9B9B9B',fontSize:'14px'}}>{t('create_collection.max_wallet')}</Box>
        </Container>
    )
}

FormBody.propTypes = {
    errors: PropTypes.any,
    values: PropTypes.any,
    setValues: PropTypes.func,
    touched: PropTypes.any,
    hiddenFileInput:PropTypes.any,
    handleDragOver: PropTypes.func,
    handleDrop: PropTypes.func,
    handleFileUpload: PropTypes.func,

    hiddenFileInputBanner: PropTypes.object,
    handleDropBanner: PropTypes.func,
    handleDragOverBanner: PropTypes.func,
    handleFileInputBanner: PropTypes.func,
    msgError: PropTypes.string,
    msgFile: PropTypes.string,
    msgFileBanner: PropTypes.string,
    
    editorState: PropTypes.any,
    setEditorState: PropTypes.func,
    editorTemp: PropTypes.any,
    setEditorTemp: PropTypes.func,
    errorEditor: PropTypes.any
}

const CreateCollection = () => {
    let maxImgSize = 52428800 // 2097152 bytes = 50 MB
    const { t } = useTranslation("translate");
    const {data} = React.useContext(Context)
    const [preview, setPreview] = useState('');
    const [bannerPreview, setBannerPreview] = useState('');
    const [showPanelVideo, setShowPanelVideo] = useState(false);
    const [showPanelBannerVideo, setShowPanelBannerVideo] = useState(false);
    const [current, setCurrent] = useState(0);
    const [currentBanner, setCurrentBanner] = useState(0);
    const hiddenFileInput = useRef(null);
    const hiddenFileInputBanner = useRef(null);
    const history = useNavigate()
    const timeVideoRef = useRef(null);
    const timeVideoBannerRef = useRef(null);
    
    const [fileBanner,setFileBanner] = useState(null)
    const [msgError, setMsgError] = useState('');
    const [msgFile,setMsgFile] = useState('');
    const [msgFileBanner,setMsgFileBanner] = useState('');

    const [editorState,setEditorState] = React.useState()
    const [editorTemp,setEditorTemp] = React.useState(null)
    const [errorEditor,setErrorEditor] = React.useState(false)

    const handleCurrentTime = (e) => {
        if(timeVideoRef.current){
            timeVideoRef.current.currentTime = e.target.value;
            setCurrent(e.target.value);
        }
    }

    const handleCurrentBannerTime = (e) => {
        if(timeVideoBannerRef.current){
            timeVideoBannerRef.current.currentTime = e.target.value;
            setCurrentBanner(e.target.value);
        }
    }

    const cancel = () => {
        history('/home')
    }

    const handleDragOver = (e)=>{
        e.preventDefault();
    }

    const handleDragOverBanner = (e)=>{
        e.preventDefault();
    }

    function removeDragData(ev) {
        if (ev.dataTransfer.items) {
            ev.dataTransfer.items.clear();
        } else {
            ev.dataTransfer.clearData();
        }
    }

    const validateDrag = (value) =>{
        if(value.kind === 'file') {
            let newFile = value.getAsFile();
            if(!isValidFormat(newFile,formats)) {setMsgFile(t("create_collection.message_error.invalid_file")); return 0;}
            setMsgFile('')
            handlePreview(newFile)
        }
    }

    const validateDragBanner = (value) =>{
        if(value.kind === 'file') {
            let newFile = value.getAsFile();
            if(!isValidFormat(newFile,formats)) {setMsgFileBanner(t("create_collection.message_error.invalid_file")); return 0;}
            setMsgFileBanner('')
            if(newFile && String(newFile.type).includes("image") && newFile.size > maxImgSize ){
                setMsgFileBanner(t("create_collection.message_error.file_is_to_big"));
                return 
            }
            let formData = new FormData();
            formData.append("files", newFile);
            setFileBanner(formData)
            handleBannerPreview(newFile)
        }
    }

    const handlePreview = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file)
        let formData = new FormData();
        formData.append("thumbnail", file);
        reader.onload = () => {
            let preview = {
                src : reader.result,
                isVideo : isVideo(file),
                data : formData,
            }
            if(preview.isVideo) {
                window.setTimeout(()=>{
                    setShowPanelVideo(true);
                }, 100)
                window.clearTimeout(timeVideoRef.current);
            }else {
                setShowPanelVideo(false);
            }
            setPreview(preview);
        };
    }

    const handleBannerPreview = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file)
        let formData = new FormData();
        formData.append("thumbnail", file);
        reader.onload = () => {
            let preview = {
                src : reader.result,
                isVideo : isVideo(file),
                data : formData,
            }
            if(preview.isVideo) {
                window.setTimeout(()=>{
                    setShowPanelBannerVideo(true);
                }, 100)
                window.clearTimeout(timeVideoRef.current);
            }else {
                setShowPanelBannerVideo(false);
            }
            setBannerPreview(preview);
        };
    }

    const handleDrop = (e)=>{
        e.preventDefault();
        let count = 0;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            for (let value of e.dataTransfer.items) {
                if(count > 0){
                    return 0;
                }
                validateDrag(value);
                count++;
            }
        }
        removeDragData(e)
    }

    const handleDropBanner = (e)=>{
        e.preventDefault();
        let count = 0;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            for (let value of e.dataTransfer.items) {
                if(count > 0){
                    return 0;
                }
                validateDragBanner(value);
                count++;
            }
        }
        removeDragData(e)
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setMsgFile('')
        if(isValidFormat(file,formats)) {
            handlePreview(file)
        } else {
            setMsgFile(t("create_collection.message_error.invalid_file"))
        }
    }

    const handleFileInputBanner = (e) => {
        const file = e.target.files[0];
        setMsgFileBanner('')
        if(file && String(file.type).includes("image") && file.size > maxImgSize ){
            setMsgFileBanner(t("create_collection.message_error.file_is_to_big"));
            return 
        }
        if(isValidFormat(file,formats)) {
            let formData = new FormData();
            formData.append("files", file);
            setFileBanner(formData)
            handleBannerPreview(file)
        } else {
            setMsgFileBanner(t("create_collection.message_error.invalid_file"))
        }
    }

    const uploadThumbnail = (thumbnail, isVideo, time = -1) => {
        return new Promise((resolve, reject) => {
            try {
                let type = isVideo ? "video" : "img";
                let url = `${process.env.REACT_APP_URL_API}/thumbnail?domain=${process.env.REACT_APP_DOMAIN}&type=${type}&time=${time}`
                console.log('url ::', url);
                let headers = {
                'Content-Type': 'multipart/form-data'
                }
                axios.post(url, thumbnail, { headers :headers}).then((success) => {
                    resolve(success.data.url)
                }).catch((err)=> reject(err))
            } catch (error) {
                reject(error)
            }
        })
    }

    const uploadBannerMedia = (file) => {
        return new Promise((resolve, reject) => {
            try {
                let url = `${process.env.REACT_APP_URL_API}/extra-files`
                let headers = {
                  'Content-Type': 'multipart/form-data'
                }
                axios.post(url, file, { headers :headers}).then((success) => {
                    resolve(success.data)
                }).catch((err)=> reject(err))
            } catch (error) {
                reject(error)
            }
        })
    }

    const  currentDateVideo = () => {
        let currentTime = -1;
        if(preview.isVideo) {
            currentTime = current
        }
        return currentTime
    }

    
    return (
        <>
            <Box sx={{marginBottom:'1rem'}}>
                    <Box 
                        component='h1' 
                        sx={{
                            fontSize:'30px',
                            textAlign:'center',
                        }}
                    >
                        {t('create_collection.title')}
                    </Box>
                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        sx={{
                            padding:'0 20vw',
                            '@media (max-width: 600px)': {
                                padding:'0 5vw',
                            }
                        }}
                    >
                        <Grid container rowSpacing={5} columns={{sm:12, md:12, lg:12, xl:12}} sx={{marginTop:'1rem'}}>
                            <Grid item sm={12} md={6} lg={8} xl={8}>
                                <Formik
                                    initialValues={{
                                        image: '',
                                        name: '',
                                        description: '',
                                        url: '',
                                        comission: [],
                                        termsAndConditions: false
                                    }}
                                    validationSchema={collectionSchema}
                                    onSubmit={async(values, { setSubmitting, resetForm }) => {
                                        try{

                                        setErrorEditor(false)
                                        if(values.description == '' || values.description && values.description.getCurrentContent && String(draftToHtml(convertToRaw(values.description.getCurrentContent()))) == "<p></p>\n"){
                                            setErrorEditor(true)
                                            return null;
                                        }
                                        window.scrollTo(0, 0);
                                        setSubmitting(true);
                                        if(preview) {
                                            if(preview.data) {
                                                console.log('Submit data ::', values)
                                                let currentTime = currentDateVideo()
                                                let thumb_url = await uploadThumbnail(preview.data, preview.isVideo,currentTime).catch((err)=>{ setMsgError(err); setSubmitting(false)});
                                                let data_banner = await uploadBannerMedia(fileBanner).catch((err)=>{ setMsgError(err); setSubmitting(false)});
                                                console.log('banner ::', data_banner)
                                                let msg = "Approve my intention of create project at";
                                                let {signature , message} = await sign(msg,data.userAccount,data.provider).catch((err)=>{ setMsgError(err); setSubmitting(false)});
                                                let { name, symbol} = nameAndSymbol(values.name);
                                                console.log('name',name)
                                                let collection = {
                                                    address_collectible : (signature.substring(1,42)+symbol),
                                                    tags: [],
                                                    categories : [],
                                                    social_networks : [],
                                                    files_url : [],
                                                    commission : [],
                                                    external_url : '',
                                                    description : String(draftToHtml(convertToRaw(values.description.getCurrentContent()))),
                                                    name : values.name,
                                                    thumb_url : thumb_url,
                                                    banner_url : data_banner.urls[0],
                                                    signature : signature,
                                                    message : message,
                                                    is_video : (preview.isVideo)? 1 : 0,
                                                    domain : process.env.REACT_APP_DOMAIN,
                                                    action : 'CREATED_PROJECT',
                                                    blockchain_name : process.env.REACT_APP_POLYGON_NETWORK_NAME,
                                                    thumb_second: preview.isVideo ? Math.round(timeVideoRef.current?.currentTime) : -1,
                                                }
                                                await axios.post(process.env.REACT_APP_URL_API+ `/project?domain=${process.env.REACT_APP_DOMAIN} `,collection)
                                                    .then((success) => {
                                                    console.log('success ::', success)
                                                    resetForm()
                                                    setSubmitting(false);
                                                    history('/create')
                                                    }).catch((error) => {
                                                        console.log('error ::', error)
                                                        setMsgError(error);
                                                        setSubmitting(true);
                                                    })
                                            }
                                        }
                                        console.log('Submit data',values);
                                        }catch(err){
                                            setMsgError(err);
                                            setSubmitting(false);
                                        }
                                    }}
                                >
                                    {({ errors, values,setValues, touched, isSubmitting}) =>(
                                    <Form name='collection'>
                                        <FormBody
                                            errors={errors}
                                            values={values}
                                            setValues={setValues}
                                            touched={touched}
                                            hiddenFileInput={hiddenFileInput}
                                            handleDragOver={handleDragOver}
                                            handleDrop={handleDrop}
                                            handleFileUpload={handleFileUpload}

                                            hiddenFileInputBanner={hiddenFileInputBanner}
                                            handleDragOverBanner={handleDragOverBanner}
                                            handleDropBanner={handleDropBanner}
                                            handleFileInputBanner={handleFileInputBanner}  
                                            msgFile={msgFile}
                                            msgFileBanner={msgFileBanner}

                                            editorState={editorState}
                                            setEditorState={setEditorState}
                                            editorTemp={editorTemp}
                                            setEditorTemp={setEditorTemp}
                                            errorEditor={errorEditor}
                                        />
                                        <Container maxWidth='sm' sx={{marginTop:'1rem'}}>
                                            <Box display='flex' justifyContent='center' alignItems='center' sx={{gap:'5px'}}>
                                                <ButtonStyled text={t('create_collection.create_btn')} type='submit'/>
                                                <ButtonStyled text={t('create_collection.cancel_btn')} type='button' onClick={cancel} />
                                            </Box>
                                        </Container>
                                        <LoaderModal
                                            setIsClosed = {() => {}}
                                            text={t('create_collection.creating_loader')}
                                            isOpen={isSubmitting}
                                            textColor='#fff'
                                            spinnerColor='#fff'
                                        />
                                    </Form>)}
                                </Formik>
                            </Grid>
                            <Grid item sm={12} md={6} lg={4} xl={4} sx={{margin:'0 auto'}}>
                                <ImagePreview timeVideoRef={timeVideoRef} title={t('create_collection.preview_image')} image={preview.src} isVideo={preview.isVideo} />
                                {(showPanelVideo) &&
                                <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{gap:'1rem', marginTop:'1rem'}}>
                                    {console.log("duration::", timeVideoRef.current?.duration)}
                                    <input type = 'range' min = '0' max = {`${timeVideoRef.current?.duration}`} step = '0.1' value = {current} onChange = {handleCurrentTime} />
                                    <div style={{textAlign:'center'}}>{t("create_collection.select_frame")}</div>
                                </Box>}
                                <ImagePreview timeVideoRef={timeVideoBannerRef} title={t('create_collection.preview_banner')} image={bannerPreview.src} isVideo={bannerPreview.isVideo} />
                                {(showPanelBannerVideo) &&
                                <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{gap:'1rem', marginTop:'1rem'}}>
                                    {/* <Slider value={videoTimer} aria-label="Default" valueLabelDisplay={timeVideoRef.current?.currentTime} onChange={handleCurrentTime} /> */}
                                    {console.log("duration::", timeVideoBannerRef.current?.duration)}
                                    <input type = 'range' min = '0' max = {`${timeVideoBannerRef.current?.duration}`} step = '0.1' value = {currentBanner} onChange = {handleCurrentBannerTime} />
                                    <div style={{textAlign:'center',color:'#FEFFFF'}}>{t("create_collection.select_frame")}</div>
                                </Box>}
                            </Grid>
                        </Grid>
                    </Box>
                    {
                        msgError != '' &&
                        <Box sx={{color:'red'}}><ErrorMessage error={msgError}  /></Box>
                    }
            </Box>
        </>
       
    )
}

export default CreateCollection
