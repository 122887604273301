import React, { useContext, useEffect, useState } from 'react';
import { Box, Button,CardMedia, Checkbox, CircularProgress, FormControlLabel, Modal, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import ShowSign from 'components/ShowSign';
import { isApproved } from 'services/ERC721/isApproved';
import { Context } from 'hooks/WalletContext';
import PropTypes from 'prop-types';
import Web3 from 'web3';
import { safeTransferNFT } from 'services/web3/ERC1155/transferNFT';
import { cancelAllRequests } from 'services/cancelAllRequests/cancelAllRequests';
import { cancelAllCryptoRequests } from 'services/ExchangeCrypto/cancelAllCryptoRequests';
import { updateOwner } from 'services/Blockchain/updateOwner';
import { sanityBurnParagraph } from 'api/sanity';

const ModalBurnNFT = ({open, onClose, projectKey,image, isVideo,nft}) => {
    const {data} = useContext(Context);
    const { t } = useTranslation("translate");
    const [isUserSigned, setIsUserSigned] = useState(false);
    const [checkBox,setCheckBox] = useState(false);
    const [error,setError] = useState('');
    const [postTX,setPosTX] = useState('');
    const [loading,setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [paragraph,setParagraph] = useState('');

    useEffect(()=>{
        setError('')
        sanityBurnParagraph().then(response=>{
            try{
                setParagraph(response?.length ? response[0].paragraph:'');
            }catch (e) {
                console.error(e)
                setError(e)
            }
        });
    },[]);

    const checkApprove = async () =>{
        if(data && nft && nft.user && nft.user.main_key &&  (data.userAccount+'').toUpperCase() == (nft.user.main_key+'').toUpperCase()) {
            let approve = await isApproved(projectKey, data.userAccount,process.env.REACT_APP_EXCHANGE);
            setIsUserSigned(approve)
        }
    }
    useEffect(()=>{
        checkApprove();
    },[])
    
    const handleCloseModal = () => {
        if(loading){
            return 0;
        }
        onClose(false);
    }

    const handleTransfer = async() => {
        setError('')
        setPosTX('')
        setLoading(true)
        setSuccess(false)
        let transfer = "0x000000000000000000000000000000000000dEaD";
        if(data && data.provider) {
            if(!Web3.utils.isAddress(transfer)) {
                setError('Address is not valid');
                setLoading(false)
                setSuccess(false);
            } else {
                safeTransferNFT(data.userAccount,nft.collection_key,transfer,data.provider,nft.token_id,(receipt) => {
                    try {
                        cancelAllRequests(nft.token_id).then(() => { 
                            cancelAllCryptoRequests(nft.token_id).then(() => {    
                                setPosTX(receipt.transactionHash)
                                setLoading(false)
                                setSuccess(true);
                            }).then((error) => {console.log(error)})
                        }).then((error) => {console.log(error)})
                        updateOwner(nft.token_id,transfer).then((success) => { nft.owner = transfer; console.log(success)}).then((error) => { console.log(error)})
                    } catch (error) {
                        console.log(error)
                    }
                },(error) => {
                    console.log('error ::', error.message)
                    if(error.code == '4001'){
                        setError(t("custom_error_metamask.cancel_transaction"));
                    }else{
                        setError("Error: "+ error.message.substring(0,100));
                    }
                    setLoading(false)
                    setSuccess(false);
                })
            }
        } else {
            setError('The provider is not ready, try later')
            setLoading(false)
            setSuccess(false);
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Paper
                sx={{
                    width: 800,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '2px solid #E5E5E5',
                    borderRadius:'8px',
                    boxShadow: 24,
                    p: 4,
                    outline: 'none',
                    ':focus': {
                        outline: 'none',
                    },
                    overflow:'auto',
                    '@media (max-width:850px)':{
                        width: '100%',
                        height: '100%'
                    },
                    '@media (max-width:830px)':{
                        width: '100%',
                        height: '100%'
                    },
                    '@media (max-width:600px)':{
                        width: '100vw',
                        height: '100%'
                    }
                }}
            >
                {!isUserSigned && <ShowSign projectKey={projectKey} image={image} isVideo={isVideo} userSigned={setIsUserSigned} onClose={onClose} />}
                {isUserSigned &&
                <>
                <Box
                    display='flex' 
                    justifyContent='flex-end' 
                    alignItems='flex-start' 
                    sx={{width:'100%'}}
                >
                    <Button type='button' onClick={handleCloseModal}> 
                        <CloseIcon />
                    </Button>
                </Box>
                <Box
                    display='flex' 
                    justifyContent='center' 
                    alignItems='flex-start' 
                >
                    <h1>{t("modal_burn_nft.title")}</h1>
                </Box>
                <center
                >
                    {success && error == '' &&
                        <Box>
                           <a style={{color:'#F344A1'}} href={process.env.REACT_APP_SCAN+'/'+postTX} target="_blank" rel="noreferrer">{t("nft-screen.nft-screen.view_in_polygonscan_text")}</a>
                        </Box>
                    }
                    <Box sx={{color:'red'}}>
                        {error}
                    </Box>
                </center>
                <Box 
                    display='flex' 
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        gap: '5rem',
                        '@media screen and (max-width: 460px)': {
                            flexDirection: 'column'
                        }
                    }}
                >
                    <CardMedia
                        component="img"
                        src={image}
                        alt='NFT'
                        muted
                        loop
                        autoPlay
                        sx={{
                            borderRadius: '10px',
                            width: '20rem',
                            height: '20rem',
                        }}
                    />
                    <Box sx={{width:'300px'}}>
                        <Box component="small" sx={{width:'100%',textAlign:'left',color:'red'}}>
                           {paragraph}
                        </Box>
                        <Box display="flex" justifyContent={"center"}>
                            <FormControlLabel
                                disabled={loading}
                                value="true"
                                control={
                                    <Checkbox sx={{
                                        '&.Mui-checked': {
                                            color: '#A659D9',
                                            }
                                        }} 
                                    />
                                }
                                label={t("modal_burn_nft.confirm_action")}
                                labelPlacement="end"
                                onChange={()=>{setCheckBox(!checkBox)}}
                            />
                        </Box>
                        <Box display="flex" justifyContent={"center"}>
                            <Button
                                disabled={loading || !checkBox}
                                sx={{
                                    width:'100px',
                                    "&.Mui-disabled":{
                                        background:'#d2d2d2'
                                    }
                                }}
                                onClick={handleTransfer}
                            >
                                {
                                    loading ? <CircularProgress size={27} sx={{color:'#000'}}/>
                                    :
                                    t("modal_burn_nft.burn_btn")
                                }
                            </Button>
                        </Box>
                    </Box>
                </Box>
                </>}
            </Paper>
        </Modal>
    )
}

ModalBurnNFT.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    image: PropTypes.string.isRequired,
    projectKey: PropTypes.string,
    isVideo: PropTypes.bool.isRequired,
    nft : PropTypes.object
}

export default ModalBurnNFT
