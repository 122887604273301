import React  from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetch } from 'hooks/useFetch';


const ListAutoComplete = ({requestIsLoad,categories,setCategories,width,onFunction}) => {

  const [open,setOpen] = React.useState(false)
  const [options,setOptions] = React.useState([]);
  let url = `${process.env.REACT_APP_URL_API}/tag?domain=${process.env.REACT_APP_DOMAIN}`;
  let {data,loading,error} = useFetch(url);
 

  const handleClickCategories = (text) => {
    setCategories(String(text));
    onFunction(String(text));
  }

  const init = async()=>{
    if(data && data.length > 0){
      setOptions(data)
    }
    if(error){
      console.log("error getCategories::",error)
      console.log(requestIsLoad,categories,setCategories,onFunction,handleClickCategories)
    }
  }

  React.useEffect(() =>{
    init();
  },[data,error])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(e.key == 'Enter' || e.key == 13) {
      onFunction(categories);
    }
  }
  const handleChangeCategories = (e) => {
    console.log("debug evt::",e)
    setCategories(String(e.target.value));
    if(e.type == "click"){
      setCategories(String(e.target.innerText?e.target.innerText:''));
      onFunction(String(e.target.innerText?e.target.innerText:''));
    }else{
      setCategories(String(e.target.value));
    }
    
  }

  return (
      <Autocomplete
        sx={{
          width: width 
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        disabled={requestIsLoad}
        inputValue={categories}
        renderInput={(params) => {
          return (
            <React.Fragment>
              <TextField
              {...params}
              label=""
              onChange={(e)=>handleChangeCategories(e)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              />
            </React.Fragment>
          )
        }}
        onChange={(e)=>handleChangeCategories(e)}
        onKeyUp={(e)=>handleSubmit(e)}
      />
  );
};


ListAutoComplete.defaultProps = {
    requestIsLoad:false,
    categories:'',
    setCategories:()=>{},
    width: 300,
    onFunction: () => {},
}

ListAutoComplete.propTypes = {
    requestIsLoad: PropTypes.bool,
    categories: PropTypes.string,
    setCategories:PropTypes.func,
    width: PropTypes.any,
    onFunction: PropTypes.func
};

export default ListAutoComplete;