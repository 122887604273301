import detectEthereumProvider from '@metamask/detect-provider';
import web3Provider from 'services/web3'
import Web3 from 'web3';
import PropTypes from 'prop-types'


const metamask = async (setMsg, setData, setIsLoading) => {
	try {
		const provider = await detectEthereumProvider();

		if (!provider) {
			alert('Metamask is not installed');
			return;
		}

		try {
			await provider.request({ method: 'eth_requestAccounts' });
		} catch (error) {
			if (error.code === -32002) {
				setMsg("Please open your Wallet in your browser");
			}
			throw error;
		}

		localStorage.setItem('isMetamask', true);
		localStorage.setItem('wallet', true);

		const setupProvider = async () => {
			try {
				window.localStorage.removeItem('signature');
				window.localStorage.removeItem('message');
				setIsLoading(true);
				const result = await web3Provider(provider);
				if (!result || (typeof result === 'object' && Object.keys(result?.user).length === 0 )) {
					localStorage.clear();
					setData(null)
				} else {
					setData(result);
				}
			} catch (error) {
				console.error('Error in setupProvider:', error);
			} finally {
				setIsLoading(false);
			}
		};

		// Handle account changes
		provider.on('accountsChanged', async () => {
			await setupProvider();
		});

		provider.removeListener('accountsChanged', async () => {
			localStorage.clear();
			window.location.reload();
		});

		// Handle chain changes
		provider.on('chainChanged', async () => {
			await setupProvider();
		});

		provider.removeListener('chainChanged', async () => {
			window.location.reload();
		});

		return await web3Provider(provider);
	} catch (error) {
		console.error('Metamask error:', error);
		return null;
	}
};

const switchEthereumChain = async(network) => {
    if (window?.ethereum) {
        try {
          if (network === 0){ return;}
            if (process.env.REACT_APP_POLYGON_NETWORK == network) {
                console.log('hex ::', Web3.utils.toHex(process.env.REACT_APP_POLYGON_NETWORK))
                let provider = {
                        chainId: Web3.utils.toHex(process.env.REACT_APP_POLYGON_NETWORK),
                        chainName: process.env.REACT_APP_POLYGON_NETWORK_NAME,
                        nativeCurrency: {
                        name: 'AVAX',
                        symbol: 'AVAX',
                        decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_RPC],
                    blockExplorerUrls:[process.env.REACT_APP_SCAN]
                }
                await window?.ethereum.request({method: "wallet_addEthereumChain", params: [provider]})
                await window?.ethereum?.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(network) }],
                });
            } else {
                await window?.ethereum?.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(network) }],
                });
            }
        } catch (error) {
            console.log('Error ::', error);
        }
    }
}

metamask.propTypes ={
    setMsg: PropTypes.func
}
export {switchEthereumChain}
export default metamask