
import axios from "axios";
import { sign } from "services/Utils/signature";
import { determineXApi } from "utils/local-storage";

export const buildUrlParams = (params) => {
	const BUILD_PARAMS = new URLSearchParams(params).toString() || '';
	const PARAMS = BUILD_PARAMS ? `&${BUILD_PARAMS}` : '';
	return PARAMS;
}

export const getUser = async (address) => {
	const res = await axios.get(process.env.REACT_APP_URL_API + `/user?main_key=${String(address).toUpperCase()}&limit=1&page=0&order=main_key&cache=false`, {
		headers: {
			'X-API-Key': determineXApi()
		}
	})

	return res?.data?.length > 0 ?  res?.data: []
}

export const getJWT = async (message, signature) => {
	const url = `${process.env.REACT_APP_URL_API}/auth?message=${message}&signature=${signature}`
	const res = await axios.get(url, {
		headers: {
			'X-API-Key': process.env.REACT_APP_X_API_KEY
		}
	})
	return res?.data || null
}

export const create = async (domain, blockchain, userToRegister) => {

	const data = {
		'domain': domain,
		'blockchain_name': blockchain,
		'homepage': '',
		'username': userToRegister.address,
		'email': userToRegister.email,
		'about': '',
		'twitter': '',
		'facebook': '',
		'profile_pic_url': '',
		'telegram': '',
		'banner_url': '',
		'role': 1,
		'message': userToRegister.message,
		'signature': userToRegister.signature,
		'action': 'USER_CREATED'
	};

	const params = {
		'message': userToRegister.message,
		'signature': userToRegister.signature
	}

	const query = buildUrlParams(params);

	const user = await axios.post(`${process.env.REACT_APP_URL_API}/user?domain=${domain}${query}`, data, {
		headers: {
			'Content-Type': 'text/plain;charset=utf-8',
			'X-API-Key': process.env.REACT_APP_X_API_KEY
		}
	});
	return user
}



const catchGetUser = async(wallet) => {
	try {
		const user = await getUser(wallet);
		return user;
	} catch (e){
		console.error(e)
		return []
	} 
}


export const createIfNotExists = async (domain, blockchain, query, provider) => {
	const { wallet, message } = query;
	const dataSigned = await sign(message, wallet, provider);
	const newUser = {
		...query,
		address: wallet,
		email:  `${wallet}@${domain}`,
		message: dataSigned.message,
		signature: dataSigned.signature,
	};
	const user = await catchGetUser(wallet);
	let apiUser = null;
	if (!user || user?.length === 0 || String(user?.[0].main_key).toUpperCase() !== String(wallet).toUpperCase()) {
		apiUser = await create(domain, blockchain, newUser);
		apiUser = {
			...apiUser,
			...(await catchGetUser(wallet)?.[0] ?? {})
		}
	} else {
		apiUser = user;
	}


	const jwt = await getJWT(dataSigned.message, dataSigned.signature);
	if (jwt) {
		window.localStorage?.setItem('userApiKey', jwt);
	} else {
		window.localStorage?.removeItem('userApiKey');
	}

	return {
		...apiUser,
		registered: true
	};
};