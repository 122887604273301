import React from 'react';
import PropTypes from 'prop-types';
import { Box,Card, CardContent, CardMedia, Divider , Grid, Tooltip} from "@mui/material";
import { Link } from 'react-router-dom';
import styled from "@emotion/styled/macro";
import { useTranslation } from 'react-i18next';
import { getThumbnail } from 'services/Thumbail/getThumbnail';

export const BigTitle = styled(Box)({
  fontSize: "30px",
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '18rem',
  display:'inline-block',
  fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
  ["@media screen and (max-width: 400px)"]: {
    fontSize: "15px"
  }
});

export const BackgroundNewCard = styled(Card)({
  borderRadius: '8px 8px 0px 0px ',
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  color: "#FFF",
  position: "relative",
  width:'100%',
  cursor: "pointer",
  border: "1px solid transparent",
  boxShadow: "none",
  transition: 'all 0.3s ease-in-out',
  ["@media (max-width: 320px)"]: {
    height: '330px'
  }
});



const CardNFT =  ({item,width,index}) => {
  const { t } = useTranslation("translate");
  return (
    <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}
        sx={{
          width:{xs:'calc(100% - 10px)',sm:'calc(100% - 20px)',md:'calc(100% - 40px)',lg:'calc(100% - 70px)'}
        }}
    >
      <CardContent
        className={`${width}-w`}
        draggable={false}
        sx={{
          padding:'0px 0px',
          width: "100%",
          height: "100%",
          "&:last-child":{
            padding:'5px 0px'
          }
        }}
      >
        <Card
          item={index}
          sx={{
            width:"100%",
            height: "100%"
          }}
        >
          <Box
            sx={{
              width: "100%",
              height:{xs:'220px',sm:'260px',md:'170px',lg:'200px',xl:'370px'},
            }}
          >
            <BackgroundNewCard sx={{ height:{xs:'220px',sm:'260px',md:'170px',lg:'200px',xl:'370px'}}}>
              <Link 
                  to={`/nft?address=${item.collection_key}&token_id=${item.token_id}&domain=${process.env.REACT_APP_DOMAIN}`}
                  style={{
                      textDecoration: 'none',
                  }}
              >
                {
                item && item.metadata.is_video ?
                <CardMedia
                    className={'card-collection'}
                    component={'video'}
                    src={item && item.thumb_url}
                    autoPlay
                    loop
                    muted
                    sx={{
                        position:'relative',
                        borderRadius: '8px',
                        height:'100%',
                        width:'100%',
                        margin:'0 auto',
                        transform: 'scale(2)'
                    }}
                />
                :
                <CardMedia
                    className={'card-collection'}
                    component={'img'}
                    src={ (item.thumbnails) ? getThumbnail(item,item.thumbnails, 650,500) : item.thumb_url}
                    sx={{
                        position:'relative',
                        borderRadius:'10px 10px 0px 0px',
                        height:'100%',
                        width:'100%',
                        margin: '0 auto',
                    }}
                />
                }
              </Link>
            </BackgroundNewCard>
          </Box>
          <Box
            sx={{
              p:1,
              height: "110px",
            }}
          >
            <Box
              className="notranslate"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tooltip title={item && item.metadata && item.metadata && item.metadata.json_data && item.metadata.json_data.name}  placement="top">
                <Box
                  component={BigTitle}
                  sx={{
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    fontSize:'20px',
                    fontWeight:'bold',
                    color:'#0D0D0D'
                  }}
                >
                  {item && item.metadata && item.metadata && item.metadata.json_data && item.metadata.json_data.name}
                </Box>
              </Tooltip>
            </Box>
            <Box
              className="notranslate"
              sx={{
                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                fontSize:'19px',
                color:'#0D0D0D',
                opacity:0.7
              }}
            >
              {
                item && item.user && item.user.username && item.user.username != item.user.username.substring(0,12)+'com' ? item.user.username
                :
                item && item.user &&  item && item.user.wallet && (item.user.wallet).substring(0,5)+ '...' +(item.user.wallet).substring(38,54)
              }
            </Box>
            <Divider/>
            { item.sale &&
              <Box
                sx={{
                  mt:'5px',
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection:'row'
                }}
              >
              
                <Box
                  sx={{
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    fontSize:'19px',
                    color:'#0D0D0D',
                    opacity:0.7
                  }}
                >
                  {t("cards.sale.price_text")}
                </Box>
                <Box
                  sx={{
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                    fontSize:'17.5px',
                    color:'#0D0D0D',
                    opacity:0.7,
                    mt:'5px',
                  }}
                >
                  {item && item.sale && item.sale.price && item.sale.price + ' ' + item.sale.coin }
                </Box>
              </Box>
            }
            
          </Box>
        </Card>
      </CardContent>
    </Grid>
  );
};

CardNFT.propTypes = {
    item: PropTypes.object,
    width: PropTypes.number,
    index: PropTypes.number
};

export default CardNFT;