import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";

export const ContainerCollectionBuy = styled(Box)`
    padding-bottom: 50px;
    min-height: calc(100vh - 120px);
`;

export const CollectionSoldOut = styled(Box)`

    text-align: center;
    font-size: 38px;
    font-weight: 600;
    flex: 1;
    letter-spacing: 2px;
    font-family:'Futura,Trebuchet MS,Arial,sans-serif ';
    @media (min-width: 600px) {
        font-size: 60px;
        letter-spacing: 10px;
    }
    @media (min-width: 960px) {
        font-size: 80px;
        letter-spacing: 30px;
    }
    @media (min-width: 1280px) {
        font-size: 100px;
        letter-spacing: 30px;
    }
    @media (min-width: 1920px) {
        font-size: 110px;
        letter-spacing: 30px;
    }
`;

export const CollectionBuyButtonLink = styled(Button)`
    max-width: 160px;
    margin-top: 24px;
    min-width: 97px;
    height: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 56px;
    color: #feffff;
    border-radius: 6px 6px;
    font-family:'Futura,Trebuchet MS,Arial,sans-serif ';
    @media (min-width: 600px) {
        display: none;
    }
`; 

export const CollectionBuyContent = styled(Box)`
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: 400px) {
        display: grid;
        grid-template-columns: 1fr;
    }
`; 

export const ContainerUserName = styled(Box)`
    font-family:'Futura,Trebuchet MS,Arial,sans-serif ';
    @media (min-width: 600px) {
        display: initial;
    }
    @media (min-width: 960px) {
        display: none;
    }
    @media (min-width: 1280px) {
        display: none;
    }
    @media (min-width: 1920px) {
        display: none;
    }
`;

export const ContainerUserNameResponsive = styled(Box)`

    display: none;
    color: #081a43;
    font-weight: bold;
    font-family:'Futura,Trebuchet MS,Arial,sans-serif ';
    @media (min-width: 600px) {
        display: none;
    }
    @media (min-width: 960px) {
        display: initial;
    }
    @media (min-width: 1280px) {
        display: initial;
    }
    @media (min-width: 1920px) {
        display: initial;
    }
`;

export const ContainerCardInfo = styled(Box)`

    width: 100%;
    display: flex;
    padding: 0px 0px;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column-reverse;
    gap: 30px;
    margin: 0px 0px;
    @media (min-width: 600px) {
        padding: 0px 15px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    @media (min-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1920px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const ContentCardInfo = styled(Box)`

    padding: 0px 0px;
    min-height: 200px;
    background: transparent;
    box-shadow: none;
    @media (min-width: 600px) {
        min-height: 400px;
        box-shadow: none;
    }
`;

export const ContentCardUserName = styled(Box)`
    display: none;
    font-size: 36px;
    font-weight: bold;
    font-family:'Futura,Trebuchet MS,Arial,sans-serif ';
    @media (min-width: 600px) {
        display: initial;
    }
    @media (min-width: 960px) {
        display: initial;
    }
    @media (min-width: 1280px) {
        display: initial;
    }
    @media (min-width: 1920px) {
        display: initial;
    }
`;

export const ContainerCardTotal = styled(Box)`

    display: none;
    font-size: 17px;
    margin-bottom:15px;
    margin-top: 8;
    min-height:25px;
    font-family:'Futura,Trebuchet MS,Arial,sans-serif ';
    @media (min-width: 600px) {
        display: flex;
    }
    @media (min-width: 960px) {
        display: flex;
    }
    @media (min-width: 1280px) {
        display: flex;
    }
    @media (min-width: 1920px) {
        display: flex;
    }
`;

export const ContainerCardPublicMint = styled(Box)`
   
    background: transparent;
    box-shadow: none;
    padding: 0px 0px;
    margin-top: 24px;
    @media (min-width: 600px) {
        box-shadow: none;
        padding: 0px 0px;
        margin-top: 0px;
    }
    @media (min-width: 960px) {
        padding: 0px 0px;
        margin-top: 0px;
    }
    @media (min-width: 1280px) {
        padding: 0px 0px;
        margin-top: 0px;
    }
    @media (min-width: 1920px) {
        padding: 0px 0px;
        margin-top: 0px;
    }
`;

export const ButtonViewCollection = styled(Button)`
    display: none;
    max-width: 160px;
    margin-top: 24px;
    min-width: 97px;
    height: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 56px;
    color: #feffff;
    border-radius: 6px 6px;
    font-family:'Futura,Trebuchet MS,Arial,sans-serif ';
    @media (min-width: 600px) {
        display: flex;
    }
    @media (min-width: 960px) {
        display: flex;
    }
    @media (min-width: 1280px) {
        display: flex;
    }
    @media (min-width: 1920px) {
        display: flex;
    }
`;

export const DescriptionText = styled(Box)`
    word-wrap: break-word;
    font-size: 20px;
    font-weight: 400;
    word-spacing:2px;
    text-align:left;
    color: #000;
    b,span{
        word-wrap: break-word;
    }
    &{
        word-wrap: break-word;
    }
`;