import React, { useContext } from 'react';
import { BrowserRouter, Route , Routes as RoutesMain, Navigate } from "react-router-dom";
import TermsAndConditions from 'views/TermsAndConditions';
import Create from 'views/Create';
import Home from 'views/Home'; 
import Main from 'layouts/Main';
import { Context } from 'hooks/WalletContext';
import { autorizedAddress } from 'config/autorizedAddress';
import Curators from 'views/Curators';
import ContainerNetwork from 'layouts/ContainerNetwork';
import Deploy from 'views/Deploy';
import Setup2 from 'views/Setup2';
import Setup1 from 'views/Setup1';
import About from 'views/About';
import FAQS from 'views/FAQS';
import RoadMap from 'views/RoadMap';
import PrivacyPolicy from 'views/PrivacyPolicy';

//Multiple db
import Explore from 'views/Explore';
import Collection from 'views/Collection';
import Profile from 'views/Profile';
import CreateCollection from  'views/CreateCollection';
import NFT from 'views/NFT';
import MintNFT from 'views/MintNFT';
import SelectCollection from  'views/SelectCollection';
import SelectCollectionPreMint from  'views/SelectCollectionPreMint';
import AdminDashboard from 'views/Admin/AdminDashboard';
import AdminManagment from 'views/Admin/AdminManagment';
import EditNFT from 'views/EditNFT';
import EditCollection from 'views/EditCollection';
import MassiveMint from 'views/MassiveMint/MassiveMint';
import CollectionBuy from 'views/CollectionBuy';


const Routes = () => {
    const { data } = useContext(Context)

    return (
        <BrowserRouter>
            <Main>
                <ContainerNetwork>
                    <RoutesMain>
                        <Route path='/' element={<Home />} />
                        <Route path='/home' element={<Home />} />
                        <Route path='/about' element={<About/>} />
                        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route path='/faqs' element={<FAQS />} />
                        <Route path='/roadmap' element={<RoadMap />} />
                        <Route path='/curators' element={<Curators />} />
                        <Route path='/explore' element={<Explore/>} />
                        <Route path='/profile' element={<Profile/>} />
                        <Route path='/nft' element={<NFT/>} />
                        <Route path='/collection' element={<Collection/>} />
                        <Route path='/collection-buy' element={<CollectionBuy/>} />

                        {data && data.user && data.user.role > -1 && data && data.userAccount ?
                        <React.Fragment>
                            <Route path='/create' element={<Create/>} />
                            <Route path='/create/create-collection' element={<CreateCollection/>} />
                            <Route path='/create/select-collection' element={<SelectCollection/>} />
                            <Route path='/create/select-collection-pre-mint' element={<SelectCollectionPreMint/>} />
                            <Route path='/create/mint-nft' element={<MintNFT/>} />
                            <Route path='/create/massive-mint' element={<MassiveMint/>} />
                        </React.Fragment> : null
                        }
                       
                        {(data && data.userAccount)  ? 
                            <React.Fragment>
                                <Route path='/create/edit-collection' element={<EditCollection/>} />
                                <Route path='/edit-nft' element={<EditNFT/>} />
                            </React.Fragment>
                            : null
                        }
                        {(data && data.userAccount) && (autorizedAddress.includes(data.userAccount)) ? 
                             <React.Fragment>
                                <Route path="/admin" element={<AdminDashboard/>} />
                                <Route path="/managment" element={<AdminManagment/>} />
                                <Route path="/deploy" element={<Deploy/>} />
                                <Route path="/setup-1" element={<Setup1/>} />
                                <Route path="/setup-2" element={<Setup2/>} />
                            </React.Fragment>
                        : null 
                        }
                        <Route path='*' element={<Navigate to="/" replace />} />
                        <Route path='*' element={<div>Not found 404</div>} />
                    </RoutesMain>
                </ContainerNetwork>
            </Main>
        </BrowserRouter>
  );
};

export default Routes;
