import React, { useContext, useEffect, useState } from 'react'
import { Box, Card, CardContent, Grid, Typography, Container, ListItemText, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Context } from 'hooks/WalletContext';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import ButtonStyled from 'components/ButtonStyled'; 
import BuyNow from '../../components/BuyNow';
import PlaceBid from '../../components/PlaceBid';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import TabPanel from './components/TabPanel';
import PropTypes from 'prop-types';


const TabPanelBid = ({nft,reloadPage,setBidTx,bids,data,value}) =>{
    let is_start = (nft) => {
        if (nft.last_bid.amount >= nft.auction.init_price ) {
            if ((nft.auction.start_date + nft.auction.time_live) < Math.floor(Date.now() / 1000)) {
                return false
            }

        }
        return true
    }
    return (
        <React.Fragment>
         {
            nft && nft.on_auction && nft.auction && nft.auction.start_now && nft.auction.start_date &&  (nft.auction.start_date + nft.auction.time_live) >= Math.floor(Date.now() / 1000) ||
            nft && nft.on_auction && nft.auction && !nft.auction.start_now  && is_start(nft) ?
            <TabPanel value={value} index={0}>
                {nft.on_auction && (
                <Container maxWidth='sm' sx={{marginTop: '30px'}}>
                    <PlaceBid
                        reloadPage = {reloadPage}
                        setBidTx = {setBidTx}
                        bid = {nft && nft.last_bid}
                        tokenId = {nft && nft.token_id}
                        projectKey={nft && nft.collection_key} 
                        userAccount={data && data.userAccount}
                        bids = {bids}
                        image={nft && nft.thumb_url_large && nft.thumb_url_large}
                        isVideo={nft && nft.metadata && nft.metadata.json_data.isVideo ? true : false}
                        finishAuction={nft && nft.auction.finish_date}
                        owner={nft.owner}
                    />
                </Container>
                )}
            </TabPanel>
            :''
        }
        
        </React.Fragment>
    )
}

TabPanelBid.propTypes = {
    nft: PropTypes.object,
    reloadPage: PropTypes.func,
    setBidTx: PropTypes.func,
    bids: PropTypes.array,
    data: PropTypes.object,
    value: PropTypes.number
}



const BodyButtons = ({nft,data,children,handleOpenDrawerWallet,t})=>{
    return (
        <React.Fragment>
            {data && data?.provider && data.userAccount && nft  && nft.user.main_key.toUpperCase() == data.userAccount.toUpperCase() ?
            <Box 
                sx={{
                    marginTop:'2rem',
                    marginBottom:'25px',
                    display: 'flex',
                    //display: 'none',
                    justifyContent: 'space-between',
                    '@media screen and (max-width: 600px)': {
                        flexDirection:'column',
                        alignItems:'center',
                    }
                }}
            >
                {children}
            </Box> 
            : (!data && nft && !nft.on_auction && !nft.on_sale &&
            <Box 
                sx={{
                    marginTop:'2rem',
                    marginBottom:'25px',
                    display: 'flex',
                    //display: 'none',
                    justifyContent: 'space-between',
                    '@media screen and (max-width: 600px)': {
                        flexDirection:'column',
                        alignItems:'center',
                    }
                }}
            >
                <ButtonStyled onClick={handleOpenDrawerWallet} width='200px' text={t('shop.connected_to_wallet_2')} />
            </Box>)}
        </React.Fragment>
    )
}

BodyButtons.propTypes = {
    nft: PropTypes.object,
    data: PropTypes.object,
    children: PropTypes.node,
    handleOpenDrawerWallet: PropTypes.func,
    t: PropTypes.any
}

const BodyAttrs = ({nft,t})=>{
    return (
        <React.Fragment>
            {nft && nft.metadata && nft.metadata.json_data.attributes.length > 0 &&
                <Typography 
                    gutterBottom variant="h5" 
                    component="h2" 
                    sx={{
                        marginTop:'25px',
                        '@media screen and (max-width: 1280px)': {
                            marginTop:'20px',
                        }
                    }}
                >
                    {t("nft-screen.attributes")}
                </Typography>
            }
            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 2, md: 4, lg: 9}}>
                {nft && nft.metadata && (nft.metadata.json_data.attributes).map((attribute, index) => (
                    attribute.trait_type != 'Description' &&  attribute.trait_type != 'Name' && attribute.trait_type != 'Number' &&
                    <Grid key={index} item xs={1} sm={1} md={2} lg={3}>
                            <Card
                            sx={{
                                border:'1px solid #E5E5E5',
                                borderRadius:'8px',
                                boxSizing: 'border-box',
                                padding: '4px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '70px',
                            }}
                        >
                           <ListItemText 
                                secondaryTypographyProps={{style: {color:'#666'}}}
                                primary={attribute.trait_type ? (attribute.trait_type) : ''} 
                                secondary={attribute.trait_type ? (attribute.value) : ''}
                                sx={{textAlign:'center'}}
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )
}

BodyAttrs.propTypes ={
    nft: PropTypes.object,
    t: PropTypes.any
}

const BodyDescription = ({nft,arrow,setArrow,t}) =>{
    return (
        <React.Fragment>
            <Typography gutterBottom variant="h5" component="h5" sx={{marginTop:'10px'}}>
                {t("nft-screen.description")}
            </Typography>
            <Box className="notranslate">
            { nft && nft.metadata && nft.metadata.json_data.description ?
                <Typography gutterBottom variant="h6" component="h2" sx={{color:'#7a8088',whiteSpace:'pre-line'}} >
                    { !arrow ?
                        <p style={{marginTop:'10px'}}>
                            {
                                nft.metadata.json_data.description.length > 500 ?
                                nft.metadata.json_data.description.substring(0,500)+' ...'
                                :
                                nft.metadata.json_data.description.substring(0,500)
                            }
                        </p>
                        :null 
                    }
                    { nft.metadata.json_data.description.length > 500  && arrow ?
                        <p style={{marginTop:'10px'}}>{nft.metadata.json_data.description}</p>
                        :null 
                    } 
                    {
                        arrow && nft.metadata.json_data.description.length > 500  && 
                        <TiArrowSortedUp onClick={()=>setArrow(!arrow)} style={{cursor:'pointer'}} size={20} />
                    }
                    {
                        !arrow && nft.metadata.json_data.description.length > 500  &&
                        <TiArrowSortedDown onClick={()=>setArrow(!arrow)} style={{cursor:'pointer'}} size={20} />
                    }
                </Typography>
                :nft && nft.metadata && nft.metadata.json_data.attributes.map((attribute) => (
                    attribute.trait_type == 'Description' && <p style={{marginTop:'10px'}}>  {attribute.value}</p>
                ))
            }
            </Box>
        </React.Fragment>
    )
}

BodyDescription.propTypes ={
    nft: PropTypes.object,
    arrow: PropTypes.bool,
    setArrow: PropTypes.func,
    t: PropTypes.any
}

const BodyCategory = ({nft,MaysFirstLetter}) =>{
    const { t } = useTranslation("translate");
    return (
        <React.Fragment>
            {
                nft && nft.tags && nft.tags.length > 0 && 
                <React.Fragment>
                    <Typography gutterBottom variant="h5" component="h5" sx={{marginTop:'10px'}}>
                        {t("nft-screen.categories")}
                    </Typography>
                    <Typography className="notranslate" gutterBottom variant="h6" component="h2" sx={{color:"#7a8088"}}>
                        {
                            nft && nft.tags && nft.tags.length > 0 ? nft.tags.map((tag, index) => {
                                return <span key={index}>{MaysFirstLetter(String(tag.name))}{index != nft.tags.length-1 && ', '}</span>
                            }): ''
                        }
                    </Typography>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

BodyCategory.propTypes = {
    nft: PropTypes.object,
    MaysFirstLetter: PropTypes.func
}

const HeaderName = ({nft})=>{
    return (
        <Typography gutterBottom variant="h4" component="h4" sx={{color:'#000'}}>
            {nft && nft.metadata && nft.metadata.json_data.name ?
                <p style={{marginTop:'10px'}}>{nft && nft.metadata && nft.metadata.json_data.name}</p>
                :  nft && nft.metadata && nft.metadata.json_data.attributes.map((attribute) => (
                    attribute.trait_type == 'Name' && <p style={{marginTop:'10px'}}>{attribute.value}</p>
                ))
            }
        </Typography>
    )
}

HeaderName.propTypes = {
    nft: PropTypes.object
}

const TabsPanel = ({nft,value,handleChange,t,reloadPage,setBidTx,bids,data})=>{
    let is_start = (nft) => {
        if (nft.last_bid.amount >= nft.auction.init_price ) {
            if ((nft.auction.start_date + nft.auction.time_live) < Math.floor(Date.now() / 1000)) {
                return false
            }

        }
        return true
    }
    return (
        <React.Fragment>
            <Box 
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    color:'#1B2635',
                    ".css-12srgyj-MuiButtonBase-root-MuiTab-root":{
                        fontWeight:'bold',
                        fontSize:'17px'
                    },
                    ".css-12srgyj-MuiButtonBase-root-MuiTab-root.Mui-selected":{
                        color:'#1B2635',
                        fontWeight:'bold',
                    },
                    ".css-1aquho2-MuiTabs-indicator":{
                        backgroundColor:'#1B2635'
                    }
                }}
            >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                    sx={{
                        ".css-12srgyj-MuiButtonBase-root-MuiTab-root":{
                            fontWeight:'bold',
                            fontSize:'17px'
                        },
                        ".css-12srgyj-MuiButtonBase-root-MuiTab-root.Mui-selected":{
                            color:'#1B2635',
                            fontWeight:'bold',
                        },
                        ".css-1aquho2-MuiTabs-indicator":{
                            backgroundColor:'#1B2635'
                        }
                    }}
                >
                    {/* <Tab disableRipple label={t("offer_view.tabs.make_offer")} /> */}
                    {
                        nft && nft.on_auction && nft.auction && nft.auction.start_now && nft.auction.start_date &&  (nft.auction.start_date + nft.auction.time_live) >= Math.floor(Date.now() / 1000) ||
                        nft && nft.on_auction && nft.auction && !nft.auction.start_now &&  is_start(nft)?
                        <Tab
                            sx={{
                                display:nft && nft.on_auction ? 'block':'none'
                            }} 
                            value={0}
                            disableRipple 
                            label={t("nft-screen.tabs.offer_title")}
                        />
                        :''
                    }
                    <Tab
                        sx={{
                            display:nft && nft.on_sale ? 'block':'none'
                        }} 
                        value={1}
                        disableRipple 
                        label={t("nft-screen.tabs.buy_title")}
                    />
                </Tabs>
            </Box>

            <TabPanelBid
                nft={nft}
                reloadPage={reloadPage}
                setBidTx={setBidTx}
                bids={bids}
                data={data}
                value={value}
            />
           
            <TabPanel value={value} index={1}>
                {nft.on_sale && (
                <Container maxWidth='sm' sx={{marginTop: '30px'}}>
                    <BuyNow
                        projectKey={nft && nft.collection_key} 
                        userAccount={data && data.userAccount}
                        image={nft && nft.thumb_url_large && nft.thumb_url_large}
                        isVideo={nft && nft.metadata && nft.metadata.json_data.isVideo ? true : false}
                        nft={nft}
                        owner={nft.owner}
                    />
                </Container>
                )}
            </TabPanel>
        </React.Fragment>
    )
}

TabsPanel.propTypes = {
    nft: PropTypes.object,
    value: PropTypes.number,
    handleChange : PropTypes.func,
    t: PropTypes.any,
    reloadPage: PropTypes.func,
    setBidTx : PropTypes.func,
    bids : PropTypes.array,
    data: PropTypes.object
}

const NFTDataMedia = ({
    nft, 
    setBidTx, 
    bids, 
    reloadPage,
    children  
    }) => {
    const {data} = useContext(Context);
    const { t } = useTranslation("translate");
    const {setOpenWallet} = useContext(DrawerMobileContext);
    const [arrow, setArrow]=useState(false);
    const [value, setValue] = React.useState(0);
    const handleOpenDrawerWallet = ()=>{
        setOpenWallet(true);
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(()=>{
        if(nft){
            if(nft.on_sale && nft.on_auction){
                setValue(0);
            }
            if(nft.on_sale && !nft.on_auction){
                setValue(1);
            } 
            if(!nft.on_sale && nft.on_auction){
                setValue(0);
            } 
        }
    },[])

    function MaysFirstLetter(string){
        if(string == "AI (ARTIFICIAL INTELLIGENCE"){
            return "AI (Artificial Intelligence)"
        }else if (string == "IA (ARTIFICIAL INTELLIGENCE)")
            return "IA (Artificial Intelligence)"
        else
        if(string == "3D"){
            return "3D"
        }else{
            let replace = String(string).toLowerCase();
            return replace.charAt(0).toUpperCase() + replace.slice(1);
        }
    }

    return (
        <Box
             sx={{
                width: '100%'
            }}
        >
            <CardContent
                sx={{
                    width: '85%',
                    margin: '0 auto',
                    '@media screen and (max-width: 1030px)': {
                        width: '100%',
                    }
                }}
            >
                <HeaderName 
                    nft={nft}
                />
                { nft && (nft.on_sale || nft.on_auction) && 
                <>
                <TabsPanel 
                    nft={nft}
                    value={value}
                    handleChange={handleChange}
                    t={t}
                    reloadPage={reloadPage}
                    setBidTx={setBidTx}
                    bids={bids}
                    data={data}
                />
                </>}
                <BodyButtons
                    nft={nft}
                    data={data}
                    handleOpenDrawerWallet={handleOpenDrawerWallet}
                    t={t}
                >
                    {children}
                </BodyButtons>
                <BodyCategory 
                    nft={nft}
                    MaysFirstLetter={MaysFirstLetter}
                />
                <BodyDescription
                    nft={nft}
                    arrow={arrow}
                    setArrow={setArrow}
                    t={t}
                />
                <BodyAttrs
                    nft={nft}
                    t={t}
                />
            </CardContent>
        </Box>
    )
}

NFTDataMedia.propTypes = {
    nft: PropTypes.object.isRequired,
    setBidTx : PropTypes.func,
    bids : PropTypes.array,
    reloadPage : PropTypes.func,
    children : PropTypes.node,
}

export default NFTDataMedia;