import React, { useContext, useState } from 'react';
import { Box, Button, CardMedia, CircularProgress } from "@mui/material";
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import { StatusTx } from 'hooks/StatusTxContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { payableMint } from 'services/ERC721/payableMint';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getCurrentPrice, totalLeft } from 'services/ERC721/totalLeft';
import { Link } from 'react-router-dom';
import { Context } from 'hooks/WalletContext';

const PublicMint = ({ collection, openModalBuy, wallet, reserve ,activeNFTPay}) => {
    const { t } = useTranslation("translate");
    const { setOpenWallet } = useContext(DrawerMobileContext);
    const { data } = useContext(Context)
    const { setStatusTx, statusTx } = useContext(StatusTx);
    const [errorTx, setErrorTx] = useState(null);
    const [successTx, setSuccessTx] = useState(null);
    const [hideElement, setHideElement] = useState(null);
    const [msgTx, setMsgTx] = useState(null);
    const colorText = '#000';
    const colorTextBtn = '#FEFFFF';
    const colorBtn = '#8742D9';
    
    const handleBuyCollection = async () => {
        setStatusTx(true);
        setMsgTx(null);
        setHideElement(true)
        try {
            let left =  await totalLeft(wallet.provider, collection.collection_key)
            let price = await getCurrentPrice(wallet.provider, collection.collection_key)
            if(left == 0) {
                window.location.reload();
            }
            payableMint(wallet.provider, wallet.userAccount, collection.collection_key, price)
            .then(async(response) => {
                if (response && response.type == 0) {
                    setStatusTx(false);
                    setErrorTx(null);
                    setSuccessTx(null);
                    setMsgTx(false);
                    setHideElement(false);
                    console.log('response type ::', response);
                } else {
                    console.log(response);
                    setStatusTx(false);
                    setErrorTx(null);
                    setSuccessTx(true);
                    setHideElement(true);
                    setMsgTx(response.transaction);
                    if ( await totalLeft(wallet.provider, collection.collection_key) == 0) {
                        window.location.reload()
                    }
                
                }
            })
            .catch((error) => {
                setMsgTx(false)
                setStatusTx(false);
                setErrorTx("Please contact support with the administrator");
                setHideElement(false);
                console.error(error);
            })
        } catch (error) {
            console.error(error);
            setStatusTx(false);
            setSuccessTx(null);
            msgTx(false);
            setHideElement(false);
            setErrorTx("Please contact support with the administrator");
        }
    };
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
                background: 'rgba(135, 66, 217, 0.25)',
                boxSizing: 'border-box',
                padding: '15px',
                borderRadius: '5px',
                mt: 2,
                mb: 2
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                sx={{
                    color:colorText,
                    fontSize:'18px'
                }}
            >
                <Box>{t("collection_buy_view.public_mint")}</Box>
                <Box display="none">Ended</Box>
            </Box>
            <Box
                width="100%"
                sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    flexDirection:{xs:'column',sm:'column',md:'column',lg:'row',xl:'row'},
                    '@media screen and (max-width: 350px)':{
                        flexDirection:'column'
                    }
                }}
            >
                {  collection.reveal &&
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap="20px"
                        sx={{
                            width:'100%'
                        }}
                    >
                        { collection.reveal && collection.reveal.price > 0 &&
                            <Box>
                                <Box>{t("collection_buy_view.price")}</Box>
                                <Box display="flex" alignItems="center" gap="5px">
                                    <CardMedia component="img" src="eth.png" alt="currency" sx={{ width: '12px' }} />
                                    <span>{collection.reveal.price}</span>
                                </Box>
                            </Box>

                        }
                        <Box>
                            <Box>Items</Box>
                            <Box>{collection.collection_of }</Box>
                        </Box>
                        <Box>
                            <Box>{t("collection_buy_view.max_items")}</Box>
                            <Box>1</Box>
                        </Box>
                    </Box>
                }
                { Object.keys(collection.reveal).length > 0 && reserve > 0 && (
                    <Box
                        gap="5px"
                        sx={{
                            width:'100%',
                            display:'flex',
                            justifyContent:{xs:'center',sm:'flex-end',md:'flex-end',lg:'flex-end',xl:'flex-end'},
                            alignItems:'center',
                            flexDirection:{xs:'row',sm:'row',md:'row',lg:'row',xl:'row'},
                            mt:{xs:'10px',sm:'10px',md:'10px',lg:'10px',xl:'10px'},
                        }}
                    >
                        {wallet && wallet.userAccount  ? (
                            <>
                                {
                                (wallet && wallet.userAccount && wallet.user ) ?
                                <React.Fragment>                              
                                    {!statusTx && !errorTx && !successTx && (
                                        <Button
                                            disabled={hideElement}
                                            type="button"
                                            onClick={handleBuyCollection}
                                            sx={{
                                                display: !hideElement ? 'initial' : 'none',
                                                color:colorTextBtn,
                                                border: '2px solid '+colorBtn,
                                                background:colorBtn,
                                                "&:hover":{
                                                    background:'#BA9BEB'
                                                },
                                                '@media screen and (max-width: 350px)':{
                                                    width:'100%'
                                                },lineHeight:1.2
                                            }}
                                        >
                                            {t("collection_buy_view.buy_with_crypto")}
                                        </Button>
                                    )}
                                    {statusTx && !errorTx && !successTx && (
                                        <CircularProgress sx={{ color: '#fff' }} />
                                    )}
                                    {!statusTx && errorTx && !successTx && (
                                        <Box>error</Box>
                                    )}
                                    {!statusTx && !errorTx && successTx && msgTx && (
                                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ mt: 2 }}>
                                            <CheckCircleIcon fontSize="large" sx={{ color: '#4aa521' }} />
                                            <a 
                                                href={`${process.env.REACT_APP_SCAN}/tx/${msgTx}`}
                                                style={{ textDecoration:'none', color: '#fff', height:'100%', textAlign: 'center' }} 
                                                target="_blank" rel="noreferrer"
                                            >
                                                {t("collection_buy_view.buy_success")}
                                                <span style={{ marginLeft: '8px'}}>
                                                    {(msgTx).substring(0,5)}...{(msgTx).substring(37,42)}
                                                </span><br/>
                                                <Box
                                                    component={Link}
                                                    to={`/profile?address=${String(data.userAccount).toUpperCase()}`}
                                                    sx={{
                                                        textDecoration:'none',
                                                        color:'#fff',
                                                        "&:hover":{
                                                            color:'#00FD90'
                                                        }
                                                    }}
                                                >
                                                    <small>
                                                        {t("collection_buy_view.go_to_profile")}
                                                    </small>
                                                </Box>
                                                
                                            </a>
                                        </Box>
                                    )}
                                </React.Fragment>
                                :
                                <Button
                                    type="button"
                                    onClick={() => setOpenWallet(true)}
                                    sx={{
                                        color:colorTextBtn,
                                        border: '2px solid '+colorBtn,
                                        background:colorBtn,
                                        "&:hover":{
                                            background:'#BA9BEB'
                                        },
                                        '@media screen and (max-width: 350px)':{
                                            width:'100%'
                                        },lineHeight:1.2
                                    }}
                                >
                                    {t("collection_buy_view.register_user")}
                                </Button>
                                }
                            </>
                        )
                        : 
                        (
                            <Button
                                type="button"
                                onClick={() => setOpenWallet(true)}
                                sx={{
                                    color:colorTextBtn,
                                    border: '2px solid '+colorBtn,
                                    background:colorBtn,
                                    "&:hover":{
                                        background:'#BA9BEB'
                                    },
                                    '@media screen and (max-width: 350px)':{
                                        width:'100%'
                                    },lineHeight:1.2
                                }}
                            >
                                {t("collection_buy_view.connect_wallet")}
                            </Button>
                        )}
                        {
                            activeNFTPay && activeNFTPay.activePay &&
                            <Button
                                disabled={hideElement}
                                type="button"
                                onClick={()=>openModalBuy(true)}
                                sx={{
                                    display: !hideElement ? 'initial' : 'none',
                                    color:colorTextBtn,
                                    border: '2px solid '+colorBtn,
                                    background:colorBtn,
                                    "&:hover":{
                                        background:'#BA9BEB'
                                    },
                                    '@media screen and (max-width: 350px)':{
                                        width:'100%'
                                    },lineHeight:1.2
                                }}
                            >
                                {t("collection_buy_view.buy_with_credit_card")}
                            </Button>
                        }
                        
                    </Box>
                )}
            </Box>
        </Box>
    );
};

PublicMint.propTypes = {
    collection: PropTypes.object,
    openModalBuy: PropTypes.func,
    reserve: PropTypes.number,
    wallet: PropTypes.object,
    activeNFTPay: PropTypes.any
};

export default PublicMint;
