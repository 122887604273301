import React, { useContext, useRef, useState } from 'react'
import { Box, Container, Grid } from '@mui/material'
import ImagePreview from 'components/ImagePreview';
import ButtonStyled from 'components/ButtonStyled';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router";
import { sign } from 'services/Utils/signature';
import { Context } from 'hooks/WalletContext'
import { isValidFormat, isVideo } from 'services/Utils/validateFileFormat';
import LoaderModal from 'components/LoaderModal';
import { useTranslation } from 'react-i18next';
import { TextArea, TextField, UploadFile } from './styles/styles';
import { useFetch } from 'hooks/useFetch';
import ErrorMessage from 'components/ErrorMessage';

const splitValidationSchema = Yup.object().shape({
    wallet: Yup.string(),
    comission: Yup.number().positive('Invalid commission'),
});

const collectionSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Too Short!')
        .max(50, 'Too Long!'),
    description: Yup.string()
        .min(3, 'Too Short!')
        .max(500, 'Too Long!'),
    image: Yup.mixed()
    .nullable() 
    //.notRequired()
    .test("FILE_SIZE", "Uploaded file is too big.",
        value => !value || (value && value.size <= 20900000000)),
    splitWallet: Yup.array().of(splitValidationSchema),
});

const formats = ['PNG','GIF','JPG','MP4','JPEG']

const EditCollection = () => {
    let maxImgSize = 2097152 // 2097152 bytes = 2 MB
    const { t } = useTranslation("translate");
    const {data} = useContext(Context)
    const [preview, setPreview] = useState('');
    const [msgFile,setMsgFile] = useState('');
    const [showPanelVideo, setShowPanelVideo] = useState(false);
    const [current, setCurrent] = useState(0);
    const hiddenFileInput = useRef(null);
    const history = useNavigate();
    const timeVideoRef = useRef(null);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const address = query.get('project_key'); 
    const collection = `${process.env.REACT_APP_URL_API}/collection?collection_key=${address}&domain=${process.env.REACT_APP_DOMAIN}`
    const { loading, error, data:project } = useFetch(collection);

    const handleCurrentTime = (e) => {
        if(timeVideoRef.current){
            timeVideoRef.current.currentTime = e.target.value;
            setCurrent(e.target.value);
        }
    }

    const cancel = () => {
        history('/home')
    }
    
    const validateDrag = (value) =>{
        if(value.kind === 'file') {
            let newFile = value.getAsFile();
            if(!isValidFormat(newFile,formats)) {setMsgFile("Invalid file"); return 0;}
            setMsgFile('')
            if(newFile && String(newFile.type).includes("image") && newFile.size > maxImgSize ){
                setMsgFile("your file is too big to be uploaded");
                return 
            }
            handlePreview(newFile)
        }
    }

    const handleDrop = (e)=>{
        e.preventDefault();
        let count = 0;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            for (let value of e.dataTransfer.items) {
                if(count > 0){
                    return 0;
                }
                validateDrag(value);
                count++;
            }
        }
        removeDragData(e)
    }

    const handleDragOver = (e)=>{
        e.preventDefault();
    }

    function removeDragData(ev) {
        if (ev.dataTransfer.items) {
            ev.dataTransfer.items.clear();
        } else {
            ev.dataTransfer.clearData();
        }
    }

    const handlePreview = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file)
        let formData = new FormData();
        formData.append("thumbnail", file);
        reader.onload = () => {
            let preview = {
                src : reader.result,
                isVideo : isVideo(file),
                data : formData,
            }
            if(preview.isVideo) {
                window.setTimeout(()=>{
                    setShowPanelVideo(true);
                }, 100)
                window.clearTimeout(timeVideoRef.current);
            }else {
                setShowPanelVideo(false);
            }
            setPreview(preview);
        };
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setMsgFile('')
        if(file && String(file.type).includes("image") && file.size > maxImgSize ){
            setMsgFile("your file is too big to be uploaded");
            return 
        }
        if(isValidFormat(file,formats)) {
            handlePreview(file)
        } else {
            setMsgFile("Invalid file")
        }
    }

    const uploadThumbnail = (thumbnail, isVideo, time = -1) => {
        return new Promise((resolve, reject) => {
            try {
                let type = isVideo ? "video" : "img";
                let url = `${process.env.REACT_APP_URL_API}/thumbnail?type=${type}&time=${time}&domain=${process.env.REACT_APP_DOMAIN}`
                console.log('url ::', url);
                let headers = {
                  'Content-Type': 'multipart/form-data'
                }
                axios.post(url, thumbnail, { headers :headers}).then((success) => {
                    resolve(success.data.url)
                })
            } catch (error) {
                reject(error)
            }
        })
    }

    const  currentDateVideo = () => {
        let currentTime = -1;
        if(preview.isVideo) {
            currentTime = current
        }
        return currentTime
    }

    

    if (loading) {
        return (
            <Box sx={{ minHeight: '100vh' }}>
                <LoaderModal text="loading..." />
            </Box>
        );
    }
    if (error) {
        return (
            <Box sx={{ minHeight: '100vh' }}>
                <ErrorMessage error={error.message} />
            </Box>
        );
    }
    return (
        <>
            <Box sx={{marginBottom:'1rem'}}>
                    <Box 
                        component='h1' 
                        sx={{
                            fontSize:'30px',
                            textAlign:'center',
                        }}
                    >
                        {t('edit_collection.title')}
                    </Box>
                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        sx={{
                            padding:'0 20vw',
                            '@media (max-width: 600px)': {
                                padding:'0 5vw',
                            }
                        }}
                    >
                        <Grid container rowSpacing={5} columns={{sm:12, md:12, lg:12, xl:12}} sx={{marginTop:'1rem'}}>
                            <Grid item sm={12} md={6} lg={8} xl={8}>
                                <Formik
                                    initialValues={{
                                        image: '',
                                        name: project && project[0].name,
                                        description: project && project[0].description,
                                        url: '',
                                        comission: [],
                                        termsAndConditions: false
                                    }}
                                    validationSchema={collectionSchema}
                                    onSubmit={async(values, { setSubmitting, resetForm }) => {
                                        window.scrollTo(0, 0);
                                        setSubmitting(true);
                                        if(preview) {
                                            if(preview.data) {
                                                console.log('Submit data ::', values);
                                                let msg = "Approve my intention of update project";
                                                let { signature , message } = await sign(msg,data.userAccount,data.provider);
                                                if (signature) {
                                                    let currentTime = currentDateVideo()
                                                    let thumb_url = await uploadThumbnail(preview.data, preview.isVideo,currentTime);
                                                    let updateCollection = {
                                                        domain : process.env.REACT_APP_DOMAIN,
                                                        project_key: address,
                                                        blockchain_name : process.env.REACT_APP_POLYGON_NETWORK_NAME,
                                                        thumb_url : thumb_url,
                                                        banner_url: thumb_url,
                                                        description : values.description,
                                                        name : values.name,
                                                        is_video : 0,
                                                    }
                                                    console.log('data to send ::', updateCollection)
                                                    await axios.put(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}`, updateCollection,{
                                                        headers:{
                                                            'Content-Type': 'text/plain;charset=utf-8',
                                                        }
                                                    })
                                                        .then((success) => {
                                                        console.log('success ::', message, success);
                                                        resetForm()
                                                        setSubmitting(false);
                                                        history('/collection?address='+address);
                                                        }).catch((error) => {
                                                            console.log('error ::', error);
                                                        })
                                                } else {
                                                    throw new Error('error to send data');
                                                }
                                            }
                                        }
                                        console.log('Submit data',values);
                                    }}
                                >
                                    {({ errors, touched, values, isSubmitting}) =>(
                                    <Form name='collection'>
                                        <Container maxWidth='sm'>
                                            <Box>
                                                <Box 
                                                    component='h1'
                                                    sx={{
                                                        fontSize:'25px',
                                                        marginBottom:'0px',
                                                        marginTop:'0px',
                                                    }}
                                                >
                                                    {t('create_collection.upload_image')}
                                                </Box>
                                                <Box 
                                                    sx={{
                                                        fontSize:'14px',
                                                        marginTop:'10px',
                                                        marginBottom:'10px',
                                                        color:'#9B9B9B',
                                                    }}
                                                >
                                                    {t('create_collection.drag_or_choose')}
                                                </Box>
                                                <Box
                                                    component='section'
                                                    display='flex'
                                                    flexDirection='column'
                                                    alignItems='center'
                                                    justifyContent='center'
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                    onClick={() => hiddenFileInput.current.click()}
                                                    sx={{
                                                        border: '1px solid #E5E5E5',
                                                        height: '200px',
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        padding: '10px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <Box 
                                                        component='article' 
                                                        display='flex' 
                                                        flexDirection='column'
                                                        alignItems='center'
                                                        justifyContent='center'
                                                    >
                                                        <UploadFileIcon htmlColor='#777E90' fontSize='large' />
                                                        <Box 
                                                            sx={{
                                                                color:'#777E90',
                                                                fontSize:'14px',
                                                            }}
                                                        >
                                                            PNG, GIF, JPG Max 5MB - MP4 Max 50MB.
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {/* <UploadFile 
                                                    type='file' 
                                                    ref={hiddenFileInput}
                                                    onChange={handleFileUpload}
                                                    value=''
                                                    inputProps={{accept:"image/png, image/gif, image/jpeg"}}
                                                /> */}
                                                <UploadFile 
                                                    data-testid='image-collection'
                                                    type='file' 
                                                    ref={hiddenFileInput}
                                                    accept="image/png, image/gif, image/jpeg, video/mp4"
                                                    name='image'
                                                    onChange={(e)=>handleFileUpload(e)}
                                                    multiple={false}
                                                />
                                            </Box>
                                            {errors.image && touched.image ? (
                                                <div style={{color:'#dc3545'}}>{errors.image}</div>
                                            ) : null}
                                            {
                                                msgFile != '' && 
                                                <div style={{color:'#dc3545'}}>{msgFile}</div>
                                            }
                                            <Box sx={{marginTop:'1rem', marginBottom:'1rem'}}>
                                                <Box 
                                                    component='label' 
                                                    htmlFor='name'
                                                    sx={{
                                                        fontSize:'25px'
                                                    }}
                                                >
                                                    {t('create_collection.collection_name')}<span style={{color:'#dc3545'}}>*</span>
                                                </Box>
                                                <TextField
                                                    id='name'
                                                    type='text'
                                                    name='name'
                                                    value={values.name}
                                                    placeholder={t('create_collection.collection_name')}
                                                />
                                                {errors.name && touched.name ? (
                                                    <div style={{color:'#dc3545'}}>{errors.name}</div>
                                                ) : null}
                                            </Box>
                                            <Box sx={{marginBottom:'1rem'}}>
                                                <Box 
                                                    component='label' 
                                                    htmlFor='description'
                                                    sx={{
                                                        fontSize:'25px'
                                                    }}
                                                >
                                                    {t('create_collection.description')} <span style={{color:'#dc3545'}}>*</span>  <Box component="span" sx={{color:'#9B9B9B',fontSize:'14px'}}>({t('create_collection.max_characters')})</Box>
                                                </Box>
                                                <TextArea
                                                    id='description'
                                                    component='textarea'
                                                    placeholder={t('create_collection.description')}
                                                    name='description'
                                                />
                                                {errors.description && touched.description ? (
                                                    <div style={{color:'#dc3545'}}>{errors.description}</div>
                                                ) : null}
                                            </Box>
                                        </Container>
                                        <Container maxWidth='sm' sx={{marginTop:'1rem'}}>
                                            <Box display='flex' justifyContent='center' alignItems='center' sx={{gap:'5px'}}>
                                                <ButtonStyled text={t('edit_collection.btn_update')} type='submit'/>
                                                <ButtonStyled text={t('create_collection.cancel_btn')} type='button' onClick={cancel} />
                                            </Box>
                                           
                                        </Container>
                                        <LoaderModal
                                            setIsClosed = {() => {}}
                                            text={t('edit_collection.modal_loader')}
                                            isOpen={isSubmitting}
                                            textColor='#fff'
                                            spinnerColor='#fff'
                                        />
                                    </Form>)}
                                </Formik>
                            </Grid>
                            <Grid item sm={12} md={6} lg={4} xl={4} sx={{margin:'0 auto'}}>
                                <ImagePreview timeVideoRef={timeVideoRef} title={t('create_collection.preview_image')} image={preview.src} isVideo={preview.isVideo} />
                                {(showPanelVideo) &&
                                <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{gap:'1rem', marginTop:'1rem'}}>
                                    {/* <Slider value={videoTimer} aria-label="Default" valueLabelDisplay={timeVideoRef.current?.currentTime} onChange={handleCurrentTime} /> */}
                                    {console.log("duration::", timeVideoRef.current?.duration)}
                                    <input type = 'range' min = '0' max = {`${timeVideoRef.current?.duration}`} step = '0.1' value = {current} onChange = {handleCurrentTime} />
                                    <div style={{textAlign:'center'}}>{t("create_collection.select_frame")}</div>
                                </Box>}
                            </Grid>
                        </Grid>
                    </Box>
            </Box>
        </>
       
    )
}

export default EditCollection;
