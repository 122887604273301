import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const MultiCategory = ({array,values,setValues,name,id}) => {

  const handleChangeList = (e,v) => {
    e.preventDefault();
    if(v && v.length <= 6){
        setValues({...values,categories: v});
    }
}
const [categories,setCategories] = React.useState([])

console.log(categories,array,name,id,handleChangeList );


const getCategories = async () => {

  axios.get(`${process.env.REACT_APP_URL_API}/tag?domain=${process.env.REACT_APP_DOMAIN}`)
    .then(res=>{
        if(res && res.data && res.data.length > 0){
          let categories = []
          res.data.forEach((e) => {
            categories.push(e.name)
          })
           setCategories(categories)
        }
    }).catch(err=>{
      setCategories([])
        console.log('Error: '+err)
    })
  }

  React.useEffect(()=>{
      getCategories()
  },[])


    

    return null
}

MultiCategory.defaultProps = {
    array: [],
    values: {},
    setValues: () => {},
    name:'',
    id:''
}

MultiCategory.propTypes = {
    array: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    name: PropTypes.string,
    id: PropTypes.string
};

export default MultiCategory;